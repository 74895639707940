import { MOBILE, DESKTOP } from '../utils/styles'
import { createStyles } from '@material-ui/styles'

export const SocialMediaIconStyles = createStyles({
  iconScaleup: {
    transform: 'scale(1.2)',
    transition: 'transform 200ms ease-out',
  },
  iconScaledown: {
    transform: 'scale(1)',
    transition: 'transform 200ms ease-out',
  },
  socialMediaIcon: {
    width: 'auto',
    margin: 8,
    [`@media ${DESKTOP}`]: {
      height: '1.2rem',
      '@media (orientation: portrait)': {
        height: '1rem',
      },
      '&:nth-of-type(4)': {
        height: '0.85rem',
      },
    },
    [`@media ${MOBILE}`]: {
      height: '1rem',
      '&:nth-of-type(4)': {
        height: '1rem',
      },
    },
  },
})

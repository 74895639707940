import React from 'react'
import clsx from 'clsx'
import { observer } from 'mobx-react'
import ReactFullpage from '@fullpage/react-fullpage'
import Landing from './landing/landing'
import Competences from './competences/competences'
import Team from './team/team'
import Clients from './clients/clients'
import Hiring from './hiring/hiring'
import Footer from '../common/footer'
import { computed, observable, runInAction, action } from 'mobx'
import FeaturedProjects from './projects/featuredProjects'
import bind from 'bind-decorator'
import { breakpoints } from '../stores/breakpointsStore'
import { enableScrollTimeout } from '../utils/constants'

const slides: any[] = [
  { component: Landing, props: { id: 'landing', data: {} } },
  { component: Competences, props: { id: 'competences', data: {} } },
  { component: FeaturedProjects, props: { id: 'featured-projects', data: {} } },
  { component: Clients, props: { id: 'clients', data: {} } },
  { component: Team, props: { id: 'team', data: {} } },
  { component: Hiring, props: { id: 'hiring', data: {} } },
  { component: Footer, props: { id: 'footer', data: {} } },
]

interface PageProps {}

@observer
class Page extends React.Component<PageProps> {
  @observable sectionIdx: number = 0
  @observable slideIndex: number = 1
  @observable sliding: boolean = false
  @observable fullpageApi: any | null = null

  componentDidMount() {
    const w = window as any
    runInAction(() => {
      if (w.fullpage_api != null) {
        this.fullpageApi = w.fullpage_api
      }
    })
  }

  @bind
  @action
  setActiveSection(idx: number) {
    this.sectionIdx = idx
  }

  @computed
  get anchors() {
    return slides.map((s) => s.props.id)
  }

  @bind
  @action
  handleLeave(origin: any, destination: any, direction: any) {
    // scroll horizontal algo from here:
    // https://stackoverflow.com/questions/32920251/fullpage-js-slide-horizontal-on-scroll
    this.sectionIdx = destination.index
    const index = origin.index
    if (Boolean(breakpoints.desktop) && this.fullpageApi != null) {
      if (index === 2 && !this.sliding) {
        if (direction === 'down' && this.slideIndex < 2) {
          this.sliding = true
          this.fullpageApi.moveSlideRight()
          this.slideIndex++
          return false
        } else if (direction === 'up' && this.slideIndex > 1) {
          this.sliding = true
          this.fullpageApi.moveSlideLeft()
          this.slideIndex--
          return false
        }
      } else if (this.sliding) {
        return false
      }
    }
  }

  @bind
  @action
  handleSlideLeave(
    anchorLink: any,
    index: any,
    slideAnchor: any,
    slideIndex: any
  ) {
    if (this.fullpageApi != null) {
      this.fullpageApi.setAllowScrolling(false)
      setTimeout(() => {
        this.fullpageApi.setAllowScrolling(true)
      }, enableScrollTimeout)
    }
    if (Boolean(breakpoints.desktop)) this.sliding = false
  }

  render() {
    const sections: JSX.Element[] = []
    if (slides.length > 0) {
      for (let i = 0; i < slides.length; i++) {
        const slide = slides[i]
        const { id } = slide.props
        sections.push(
          <div
            key={id}
            style={{ zIndex: i }}
            className={clsx(
              'section',
              id === 'footer' ? 'fp-auto-height' : undefined
            )}
          >
            <slide.component
              id={id}
              {...slide.props}
              active={this.sectionIdx === i}
              fullpageApi={this.fullpageApi}
            />
          </div>
        )
      }
    }

    return (
      <ReactFullpage
        // debug
        //fullpage options
        animateAnchor={false}
        anchors={this.anchors}
        licenseKey={'39614BC3-00294F80-B8B6ECF0-428BB41B'}
        // scrollingSpeed = {500}
        onLeave={this.handleLeave}
        afterSlideLoad={this.handleSlideLeave}
        touchSensitivity={Boolean(breakpoints.phone) ? 5 : 10}
        // responsiveSlides
        // sectionsColor={['#f2f2f2', '#4BBFC3', '#7BAABE', 'whitesmoke', '#000']}
        recordHistory={false}
        render={({ state, fullpageApi }: any) => {
          return <>{sections}</>
        }}
      />
    )
  }
}

export default Page

export interface IPartnerData {
  url?: string
  name?: string
}
export interface IProjectData {
  id: string
  name: string
  competence: string
  description: string
  img: string
  imgFallback?: string
  tags: string[]
  url?: string
  partners?: IPartnerData[]
  projectTypes: string[]
}

export interface IProjectsData {
  projects: IProjectData[]
}

// eslint-disable-next-line import/no-webpack-loader-syntax
export const ProjectsData: IProjectsData = require('!js-yaml-loader!./projects_data.yaml')

let id = 1
for (let p of ProjectsData.projects) {
  p.id = `${id++}`
}

// console.log("LOADED ", ProjectsData)

import { MOBILE, DESKTOP, TABLET } from '../../utils/styles'
import { createStyles } from '@material-ui/styles'
import { windowStore } from '../../stores/windowStore'

const techButtonScale = {
  x: windowStore.width / 70,
  y: windowStore.height / 70,
}

export const CompetencesStyles = createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    // position: 'absolute',
    // top: 0,
    paddingTop: '8vh',
    minHeight: windowStore.height,
    width: '100%',
    /* -webkit-overflow-scrolling: touch;  */
    [`@media ${MOBILE}`]: {
      width: '100vw',
    },
  },
  background: {
    /* height: 100vh; */
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: -10,
    '& $bg1, $bg2': {
      height: '100%',
      width: '100.1%',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100% 100%',
      position: 'absolute',
    },
  },
  technologies: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'center',
    // marginBottom: ,
    position: 'relative',
    maxHeight: '3rem',
    overflow: 'hidden',
    // position: 'absolute',
    // bottom: '2rem',
    // left: 0,
    [`@media ${MOBILE}`]: {
      position: 'absolute',
      bottom: '2rem',
    },
    '& $bg1, $bg2': {
      height: '100%',
      // width: '100.1%',
      width: 'auto',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100% 100%',
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
    },
  },
  bg1: {},
  animatedBg1: {
    animation: '$move1 linear infinite',
  },
  animatedBg2: {
    animation: '$move2 linear infinite',
  },
  animatedBg3: {
    animation: '$move3 linear infinite',
  },
  animatedBg4: {
    animation: '$move4 linear infinite',
  },
  animatedBg5: {
    animation: '$move5 linear infinite',
  },
  animatedBg6: {
    animation: '$move6 linear infinite',
  },

  bg2: {
    left: 0,
    /* right: 0; */
  },
  animatedBg: {
    willChange: 'transform',
  },
  slideContainer: {
    paddingLeft: 10,
    paddingRight: 10,
  },

  columnsBox: {
    display: 'flex',
    position: 'relative',
    flex: 1,
    transitionTimingFunction: 'ease-in-out',

    /* -webkit-overflow-scrolling: touch;  */
    [`@media ${DESKTOP}`]: {
      flexDirection: 'row',
      maxWidth: 2048,
      alignSelf: 'center',
    },
    [`@media ${MOBILE}`]: {
      flexDirection: 'column',
      /* height: 100vh; */
      width: '100vw',
      position: 'absolute',
      bottom: '-8.5rem',
    },
  },

  column: {
    display: 'flex',
    flexDirection: 'column',

    [`@media ${DESKTOP}`]: {
      justifyContent: 'center',
      flex: 1,
    },
    [`@media ${MOBILE}`]: {
      padding: '10% 10vw !important',
    },
  },

  columnImage: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    marginBottom: '1.5rem',
    [`@media ${TABLET}`]: {
      marginBottom: '1rem',
    },
    '& > img': {
      [`@media ${DESKTOP}`]: {
        '@media (orientation: landscape)': {
          width: 'auto',
          height: '100%',
        },
        '@media (orientation: portrait)': {
          width: '100%',
          height: 'auto',
        },
      },
      [`@media ${MOBILE}`]: {
        width: 'auto',
        height: '100%',
      },
    },
  },
  columnTextContent: {
    display: 'flex',
    flexDirection: 'column',
    [`@media ${DESKTOP}`]: {
      minHeight: '30%',
      '& a': {
        marginTop: 'auto',
      },
    },
  },
  columnTitle: {
    textTransform: 'uppercase',
    fontSize: '1rem',
    fontWeight: 700,
    marginTop: 50,
    [`@media ${DESKTOP}`]: {
      fontSize: '0.8rem',
      lineHeight: 1.5,
      marginTop: 14,
    },
    [`@media ${TABLET}`]: {
      fontSize: '0.7rem',
      lineHeight: 1.5,
      marginTop: '0.75rem',
    },
    [`@media ${MOBILE}`]: {
      fontSize: '0.9rem',
      marginTop: 24,
    },
  },
  columnText: {
    [`@media ${DESKTOP}`]: {
      fontSize: '0.8rem',
      lineHeight: '1.1rem',
      marginTop: 14,
      marginBottom: 24,
    },
    [`@media ${TABLET}`]: {
      fontSize: '0.7rem',
      lineHeight: 1.5,
      marginBottom: '1rem',
    },
    [`@media ${MOBILE}`]: {
      fontSize: '0.9rem',

      marginTop: 8,
      marginBottom: 20,
    },
  },
  learnMoreButton: {
    fontSize: '0.875rem',
    fontWeight: 'bold',
    padding: '0.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#3BB5A2',
    [`@media ${DESKTOP}`]: {
      width: '10rem',
      height: '2.25rem',
      borderRadius: 5,
      '&:hover': {
        boxShadow: 'inset 0 0 20px 20px rgba(0, 0, 0, 0.1)',
      },
    },
    [`@media ${TABLET}`]: {
      width: '8rem',
      fontSize: '0.7rem',
      height: '1.8rem',
    },
    [`@media ${MOBILE}`]: {
      width: '8rem',
      height: '2.25rem',
      // alignSelf: 'center',
      borderRadius: 5,
    },
  },
  logo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& img': {
      // filter: 'grayscale(1) brightness(1.5)',
      height: '100%',
      width: 'auto',
    },
    [`@media ${DESKTOP}`]: {
      '& img': {
        maxHeight: '2rem',
        minHeight: '2rem',
        maxWidth: '5rem',
      },
    },
    [`@media ${MOBILE}`]: {
      margin: '0 1rem',
      '& img': {
        maxHeight: '2rem',
        minHeight: '2rem',
        maxWidth: '4rem',
      },
    },
  },
  techButton: {
    height: '3rem',
    width: '3rem',
    position: 'absolute',
    bottom: '3rem',
    right: '3rem',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    transition:
      'transform 700ms cubic-bezier(0.52, 0.16, 0.24, 1), background-color 700ms cubic-bezier(0.52, 0.16, 0.24, 1)',
    '&:hover': {
      // boxShadow: 'inset 0 0 20px 20px rgba(0, 0, 0, 0.1)',
    },
    '&$techOpen': {
      backgroundColor: '#000',
      transform: 'rotate(0deg)',
    },
    '&$techClosed': {
      backgroundColor: '#6769C9',
      transform: 'rotate(45deg)',
    },
    '& > img': {
      // transition: 'transform 500ms ease-in',
      height: '1rem',
      width: '1rem',
    },
  },
  techOpen: {},
  techClosed: {},
  techBg: {
    height: 70 * 2 * Math.sqrt(techButtonScale.x ** 2 + techButtonScale.y ** 2),
    width: 70 * 2 * Math.sqrt(techButtonScale.x ** 2 + techButtonScale.y ** 2),
    position: 'absolute',
    bottom: `calc(3rem + 35px - ${
      70 * Math.sqrt(techButtonScale.x ** 2 + techButtonScale.y ** 2)
    }px)`,
    right: `calc(3rem + 35px - ${
      70 * Math.sqrt(techButtonScale.x ** 2 + techButtonScale.y ** 2)
    }px)`,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition:
      'transform 700ms cubic-bezier(0.52, 0.16, 0.24, 1), background-color 700ms cubic-bezier(0.52, 0.16, 0.24, 1)',
    '&$techOpen': {
      backgroundColor: '#000',
      // transform: `scale(${
      //   2 * Math.sqrt(techButtonScale.x ** 2 + techButtonScale.y ** 2)
      // })`,
      transform: 'scale(1)',
    },
    '&$techClosed': {
      backgroundColor: '#6769C9',
      // transform: 'scale(1)',
      transform: `scale(${
        1 / (2 * Math.sqrt(techButtonScale.x ** 2 + techButtonScale.y ** 2))
      })`,
    },
  },
  '@keyframes move1': {
    from: { transform: 'translateX(-100%)' },
    to: { transform: 'translateX(0)' },
  },

  '@keyframes move2': {
    from: { transform: 'translateX(0)' },
    to: { transform: 'translateX(100%)' },
  },
  '@keyframes move3': {
    from: { transform: 'translateX(100%)' },
    to: { transform: 'translateX(200%)' },
  },
  '@keyframes move4': {
    from: { transform: 'translateX(200%)' },
    to: { transform: 'translateX(300%)' },
  },
  '@keyframes move5': {
    from: { transform: 'translateX(300%)' },
    to: { transform: 'translateX(400%)' },
  },
  '@keyframes move6': {
    from: { transform: 'translateX(400%)' },
    to: { transform: 'translateX(500%)' },
  },
})

import * as React from 'react'
import { observer } from 'mobx-react'
import { observable, action } from 'mobx'
import { withStyles, WithStyles } from '@material-ui/styles'
import { SocialMediaIconStyles } from './socialMediaIcon.styles'
import bind from 'bind-decorator'

interface IProps {
  src: string
  to: string
}

type StyleProps = WithStyles<typeof SocialMediaIconStyles>

@observer
class SocialMediaIcon extends React.Component<IProps & StyleProps> {
  @observable buttonHover = false

  @bind
  @action
  toggleHover() {
    this.buttonHover = !this.buttonHover
  }

  render() {
    const { classes } = this.props
    return (
      <div
        className={
          this.buttonHover ? classes.iconScaleup : classes.iconScaledown
        }
        onMouseEnter={this.toggleHover}
        onMouseLeave={this.toggleHover}
      >
        <a href={this.props.to} target="_blank" rel="noopener noreferrer">
          <img
            className={classes.socialMediaIcon}
            alt="social-media-icon"
            src={this.props.src}
          />
        </a>
      </div>
    )
  }
}

export default withStyles(SocialMediaIconStyles, { name: 'SocialMediaIcon' })(
  SocialMediaIcon
)

import * as React from 'react'
import { WithStyles, withStyles } from '@material-ui/styles'
import { ProjectTagStyles } from './projectTag.styles'

interface IProps {
  tag: string
}

type StyleProps = WithStyles<typeof ProjectTagStyles>

export class ProjectTag extends React.Component<IProps & StyleProps> {
  render() {
    const { classes } = this.props
    return <div className={classes.projectTag}>{this.props.tag}</div>
  }
}

export default withStyles(ProjectTagStyles, { name: 'ProjectTag' })(ProjectTag)

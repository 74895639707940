import { MOBILE, DESKTOP } from '../../utils/styles'
import { createStyles } from '@material-ui/styles'

import nextIcon from '../../resources/icons/next.svg'

export const LandingStyles = createStyles({
  landingContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '8vh',
    width: '100%',
    position: 'relative',
    height: '100%',
    '& $cloud, $sun, $markerA, $markerB': {
      position: 'absolute',
    },
    '& $car, $moto, $velo': {
      height: 'auto',
      position: 'absolute',
      zIndex: 5,
    },
  },
  bgBlack: {
    backgroundImage: 'url(./illustrations/01_Landschaft_schwarz.svg)',
    /* height: 27%; */
    bottom: '-4px',
    /* background-size: 150%; */
  },
  bgWhite: {
    backgroundImage: 'url(./illustrations/02_Landschaft_weiss.svg)',
    /* height: 37%; */
    /* background-size: 135%; */
  },
  bgLightGrey: {
    backgroundImage: 'url(./illustrations/03_Landschaft_hellgrau.svg)',
    /* height: 45%; */
    /* background-size: 120%; */
  },
  bgGrey: {
    backgroundImage: 'url(./illustrations/04_Landschaft_grau.svg)',
    /* height: 55%; */
    /* background-size: 100%; */
  },
  text: {
    textAlign: 'center',
    flex: '30%',
    [`@media ${DESKTOP}`]: {
      fontSize: '1.1rem',
      display: 'flex',
      flexDirection: 'row',
      width: '30rem',
      alignSelf: 'center',
      // flexShrink: 0,
    },
    [`@media ${MOBILE}`]: {
      fontSize: '0.9rem',
      paddingLeft: '12%',
      paddingRight: '12%',
    },
  },
  animationBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    flex: '100%',
    height: '100%',
    position: 'relative',
  },
  horizontal: {
    display: 'flex',
    flexDirection: 'row',
    height: '80%',
  },
  right: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    flex: 1,
    paddingTop: '1em',
  },
  center: {
    display: 'flex',
    flexDirection: 'column',
    flex: 2,
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  left: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    flex: 1,
    paddingTop: '1em',
  },
  bottom: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    flex: 1,
  },

  // @media (--phone) {
  //   .bottom {
  //     /* bottom: 3vh; */
  //   }
  // }

  // @media (--desktop) {
  //   .bottom {
  //     /* bottom: 2vh; */
  //   }
  // }
  logo: {
    [`@media ${DESKTOP}`]: {
      height: '1.6rem',
      marginTop: '2vh',
    },
    [`@media ${MOBILE}`]: {
      height: '1.2rem',
    },
  },
  cloud: {
    [`@media ${DESKTOP}`]: {
      height: '13rem',
    },
    [`@media ${MOBILE}`]: {
      width: '4rem',
      marginLeft: 0,
    },
  },
  sun: {
    [`@media ${DESKTOP}`]: {
      width: '16rem',
      right: '1rem',
    },
    [`@media ${MOBILE}`]: {
      height: '6rem',
      right: 0,
      marginRight: '-50%',
    },
  },
  markerA: {
    [`@media ${DESKTOP}`]: {
      width: '4rem',
    },
    [`@media ${MOBILE}`]: {
      width: '3.5rem',
    },
  },
  markerB: {
    [`@media ${DESKTOP}`]: {
      width: '5rem',
      right: '11rem',
    },
    [`@media ${MOBILE}`]: {
      width: '5rem',
      right: '0.1em',
    },
  },
  car: {
    [`@media ${DESKTOP}`]: {
      marginLeft: 30,
      marginBottom: 15,
      width: '8rem',
    },
    [`@media ${MOBILE}`]: {
      marginLeft: 15,
      width: '7rem',
      bottom: '1rem',
    },
  },
  moto: {
    [`@media ${DESKTOP}`]: {
      width: '5rem',
    },
  },
  velo: {
    [`@media ${DESKTOP}`]: {
      width: '5rem',
    },
  },
  animatedCar: {
    [`@media ${DESKTOP}`]: {
      animation: '$drive 10s linear infinite',
    },
    [`@media ${MOBILE}`]: {
      animation: '$drive 5s linear infinite',
      WebkitAnimation: '$drive 5s linear infinite',
      MozAnimation: '$drive 5s linear infinite',
      MsAnimation: '$drive 5s linear infinite',
      OAnimation: '$drive 5s linear infinite',
    },
  },
  digitalHumanButton: {
    // backgroundColor: '#ff715b',
    position: 'relative',
    background:
      'linear-gradient(90deg, rgba(58,181,161,1) 0%, rgba(102,102,204,1) 75%)',
    fontWeight: 'bold',
    fontSize: '0.875rem',
    lineHeight: 1.25,
    textAlign: 'center',
    padding: '0.5rem 1.5rem 0.5rem 0.5rem',
    width: '13rem',
    borderRadius: 5,
    marginBottom: '2rem',
    boxShadow: 'inset 0 0 20px 20px transparent',
    transition: 'box-shadow 200ms ease',
    animation: '1.75s ease-in-out 0s infinite normal both running $heartbeat',
    '&:hover': {
      boxShadow: 'inset 0 0 20px 20px rgba(0, 0, 0, 0.1)',
    },
    '&::after': {
      content: '""',
      backgroundImage: `url(${nextIcon})`,
      position: 'absolute',
      right: '0.75rem',
      top: '43%',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      height: '0.6rem',
      width: '0.6rem',
    },
  },

  '@keyframes drive': {
    from: {
      transform: 'translate(-30vw, 0px) rotate(0deg)',
    },
    to: {
      transform: 'translate(110vw, 0px) rotate(0.02deg)',
    },
  },
  '@keyframes heartbeat': {
    '0%': { transform: 'scale(1)', animationTimingFunction: 'ease-out' },
    '10%': { transform: 'scale(0.93)', animationTimingFunction: 'ease-in' },
    '17%': { transform: 'scale(0.98)', animationTimingFunction: 'ease-out' },
    '33%': { transform: 'scale(0.89)', animationTimingFunction: 'ease-in' },
    '45%': { transform: 'scale(1)', animationTimingFunction: 'ease-out' },
  },

  // '@-webkit-keyframes drive': {
  //   from: { transform: 'translate(-30vw, 0px) rotate(0deg)'},
  //   to: {transform: 'translate(110vw, 0px) rotate(0.02deg)'}
  // },
  // '@-moz-keyframes drive': {
  //   from: { transform: 'translate(-30vw, 0px) rotate(0deg)'},
  //   to: {transform: 'translate(110vw, 0px) rotate(0.02deg)'}
  // },
  // '@-o-keyframes drive': {
  //   from: { transform: 'translate(-30vw, 0px) rotate(0deg)'},
  //   to: {transform: 'translate(110vw, 0px) rotate(0.02deg)'}
  // }
})

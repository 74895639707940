import { observable, action, computed } from 'mobx'

class Window {
  @observable width = window.innerWidth
  @observable height = window.innerHeight

  constructor() {
    window.addEventListener(
      'resize',
      () => {
        this.updateViewportHeight()
        this.updateViewportWidth()
      },
      true
    )

    // setInterval(() => {
    //   window.scrollTo(0, -1)
    //   this.updateViewportHeight()
    //   this.updateViewportWidth()
    // }, 1000)
  }

  @action
  updateViewportHeight = () => {
    if (window.innerHeight !== this.height) {
      this.height = window.innerHeight
    }
  }

  @action
  updateViewportWidth = () => {
    if (window.innerWidth !== this.width) {
      this.width = window.innerWidth
    }
  }

  @computed
  get isLandscape() {
    return this.height < this.width
  }

  @computed
  get isPortrait() {
    return this.height >= this.width
  }
}

export const windowStore = new Window()

// @ts-ignore
window.windowStore = windowStore

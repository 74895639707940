import React from 'react'
import createStyles from '@material-ui/styles/createStyles'
import { DESKTOP, MOBILE } from './utils/styles'
import withStyles, { WithStyles } from '@material-ui/styles/withStyles'
import IframeResizer from 'iframe-resizer-react'
import logo from './resources/icons/Codelane_logo_weiss.svg'
import homeLogo from './resources/icons/home.svg'
import Footer from './common/footer'
import { Helmet } from 'react-helmet'

const JobsStyles = createStyles({
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    backgroundColor: '#232323',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'auto',
  },
  iframe: {
    width: '100%',
    [`@media ${DESKTOP}`]: {
      maxWidth: 1200,
    },
  },
  header: {
    display: 'flex',
    marginTop: '2rem',
    '& > div': {
      marginRight: '0.5rem',
      marginTop: ' 0.1rem',
      fontSize: ' 1.125rem',
    },
    [`@media ${MOBILE}`]: {
      marginTop: '1rem',
    },
  },
  intro: {
    textAlign: 'center',
    [`@media ${DESKTOP}`]: {
      maxWidth: 750,

      marginTop: '1rem',
      marginBottom: '2rem',
      fontSize: '0.925rem',
    },
    [`@media ${MOBILE}`]: {
      maxWidth: '75%',
      marginTop: '1rem',
      marginBottom: '1.5rem',
    },
  },
  logo: {
    width: '5rem',
    [`@media ${DESKTOP}`]: {
      '@media (orientation: portrait)': {
        width: '3rem',
      },
    },
  },
  home: {
    position: 'absolute',
    top: '1rem',
    right: '1rem',
    width: '1.5rem',
    height: '1.5rem',
    [`@media ${MOBILE}`]: {
      width: '1.25rem',
      height: '1.25rem',
    },
  },
  homeLogo: {
    width: '100%',
    height: '100%',
    '&:hover': {
      animation: '$fade 500ms forwards',
    },
  },
  footer: {
    width: '100%',
  },
  '@keyframes fade': {
    '0%': { opacity: 1 },
    '100%': { opacity: 0.7 },
  },
})

const titleText = 'Code Lane GmbH Jobs'

const introText = `You love technology, think the metaverse could become a real thing,
and you are strong believer in free ice cream? Check out our open
positions and don’t hesitate to apply!`

type StyleProps = WithStyles<typeof JobsStyles>

class Jobs extends React.Component<StyleProps> {
  render() {
    const { classes } = this.props
    return (
      <div className={classes.container}>
        <Helmet>
          <title>{titleText}</title>
          <meta name="description" content={introText} />
          <meta property="og:title" content={titleText} />
          <meta property="og:description" content={introText} />
        </Helmet>
        <a className={classes.home} href="/">
          <img className={classes.homeLogo} src={homeLogo} alt="logo" />
        </a>
        <div className={classes.header}>
          <div>jobs</div>
          <div>@</div>
          <img className={classes.logo} src={logo} alt="logo" />
        </div>
        <div className={classes.intro}>{introText}</div>
        <div className={classes.iframe}>
          <IframeResizer
            heightCalculationMethod="lowestElement"
            src="https://jobs.dualoo.com/portal/b304iihj"
            style={{ width: '100%', minWidth: '100%' }}
            title="jobs"
            frameBorder="0"
            className={classes.iframe}
          />
        </div>
        <div className={classes.footer}>
          <Footer />
        </div>
      </div>
    )
  }
}

export default withStyles(JobsStyles, { name: 'Jobs' })(Jobs)

import * as React from 'react'

import classnames from 'clsx'
import { withStyles, WithStyles } from '@material-ui/styles'
import { SwipeDotsStyles } from './swipeDots.style'

interface IProps {
  count: number
  activeSlideIdx: number
  theme?: string // white or black
  className?: string

  scroll: number
  distanceToArrows?: string // negative value e.g -50vh
}

type StyleProps = WithStyles<typeof SwipeDotsStyles>

class SwipeDots extends React.Component<IProps & StyleProps> {
  render() {
    const { classes } = this.props
    const dots = []
    for (let i = 0; i < this.props.count; i++) {
      dots.push(
        <span
          key={i}
          className={classnames(
            classes.dot,
            i === this.props.activeSlideIdx ? classes.dotWhite : null
          )}
          style={{ backgroundColor: this.props.theme ?? 'white' }}
        ></span>
      )
    }

    /*
    let arrows: JSX.Element | null = null
    if (this.props.distanceToArrows) {
      arrows = (
        <div className={classes.arrows}>
          <div
            className={classes.left}
            style={{ top: this.props.distanceToArrows }}
          >
            <i
              className={classnames(
                classes.leftArrow,
                this.props.scroll > -0.1 && this.props.scroll < 1
                  ? classes.animatedArrow
                  : null
              )}
              style={{ borderColor: `${this.props.theme}` }}
            ></i>
          </div>
          <div
            className={classes.right}
            style={{ top: this.props.distanceToArrows }}
          >
            <i
              className={classnames(
                classes.rightArrow,
                this.props.scroll > -0.1 && this.props.scroll < 1
                  ? classes.animatedArrow
                  : null
              )}
              style={{ borderColor: `${this.props.theme}` }}
            ></i>
          </div>
        </div>
      )
    }
    */

    return (
      <div className={classnames(classes.dots, this.props.className)}>
        {/* {arrows} */}
        {dots}
      </div>
    )
  }
}

export default withStyles(SwipeDotsStyles, { name: 'SwipeDots' })(SwipeDots)

import { MOBILE, DESKTOP } from '../utils/styles'
import { createStyles } from '@material-ui/styles'

export const ArrowDownStyles = createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    bottom: 0,
    left: 0,
    [`@media ${MOBILE}`]: {},
    [`@media ${DESKTOP}`]: {
      maxHeight: 50,
    },
  },
  arrow: {
    height: 'auto',
    cursor: 'pointer',
    position: 'absolute',
    top: 0,
    zIndex: -1,
  },

  animatedArrow: {
    animation: '$move 2s ease-in-out infinite',
    WebkitAnimation: '$move 2s ease-in-out infinite',
    MozAnimation: '$move 2s ease-in-out infinite',
    MsAnimation: '$move 2s ease-in-out infinite',
    OAnimation: '$move 2s ease-in-out infinite',
    [`@media ${DESKTOP}`]: {
      width: '2rem',
    },
    [`@media ${MOBILE}`]: {
      width: '1.5rem',
    },
  },

  '@keyframes move': {
    '0%': { transform: 'translateY(0%)' },
    '50%': { transform: 'translateY(50%)' },
    '100%': { transform: 'translateY(0%)' },
  },
})

// generate unique indices in range
export function generateEmptyIndices (lower: number, upper: number, length: number): number[] {
  const arr: number[] = []

  while (arr.length < length) {
    const rand = Math.floor(Math.random() * (upper - lower) + lower)
    if (arr.indexOf(rand) === -1) {
        // Yay! new random number
      arr.push(rand)
    }
  }

  return arr
}

// shuffle array
export function shuffle (array: any[]) {
  let currentIndex = array.length
  let temporaryValue
  let randomIndex

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex -= 1

    // And swap it with the current element.
    temporaryValue = array[currentIndex]
    array[currentIndex] = array[randomIndex]
    array[randomIndex] = temporaryValue
  }

  // console.log('shuffled array', array)

  return array
}

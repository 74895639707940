import * as React from 'react'

import classnames from 'clsx'

import { LazyImageStyles } from './lazyImage.styles'
import { observer } from 'mobx-react'
import { observable, action } from 'mobx'
import bind from 'bind-decorator'
import { WithStyles, withStyles } from '@material-ui/styles'
// import { CircularProgress } from '@material-ui/core'
import { isIE } from '../../utils/platform'
import transparentSrc from './transparent.png'
require('lazysizes/plugins/attrchange/ls.attrchange')
require('lazysizes')
require('lazysizes/plugins/parent-fit/ls.parent-fit')
require('lazysizes/plugins/object-fit/ls.object-fit')
require('lazysizes/plugins/blur-up/ls.blur-up')
// require('intersection-observer') // polyfill for safari desktop

interface ILazyImage extends WithStyles<typeof LazyImageStyles> {
  src: string | null
  srcFallback?: string | null
  isMoving?: boolean
  alt?: string
  style?: React.CSSProperties
}

@observer
export class LazyImageCls extends React.Component<ILazyImage> {
  @observable loaded = false
  @observable src: string = ''

  componentDidMount() {
    this.setImgSrc()
    // document.addEventListener('lazybeforeunveil', function (e) {
    //   if (e.target) {
    //     var bg = e.target.getAttribute('data-bg')
    //     if (bg) {
    //       e.target.style.backgroundImage = 'url(' + bg + ')'
    //     }
    //   }
    // })
  }

  componentDidUpdate(prevProps: ILazyImage) {
    if (prevProps.src !== this.props.src) {
      this.setImgSrc()
    }
  }

  @bind
  @action
  setImgSrc() {
    if (this.props.src != null && this.props.src !== 'TBD') {
      this.src = this.props.src
      return
    }
    this.setDefaultSrc()
  }

  @bind
  @action
  setDefaultSrc() {
    this.src = this.props.srcFallback != null ? this.props.srcFallback : ''
  }

  @bind
  @action
  setLoaded() {
    this.loaded = true
  }

  render() {
    const { classes, style, alt } = this.props
    let content: JSX.Element | null = null
    content =
      // <InView triggerOnce rootMargin="200px 0px">
      //   {({ inView, ref }) =>
      isIE ? (
        <div
          // ref={ref}
          className={classnames(
            classes.bgImage
            // inView ? classes.loaded : undefined
          )}
          data-bg={alt ?? ''}
          style={{
            backgroundImage: `url(${this.src})`,
            backgroundSize: 'contain',
          }}
        />
      ) : (
        <img
          // ref={ref}
          className={classnames(
            'lazyload',
            classes.container,
            // this.loaded ? classes.loaded : undefined,
            { [classes['ken-burns']]: this.props.isMoving }
          )}
          // data-expand="-200"
          data-src={this.src}
          src={transparentSrc}
          onLoad={this.setLoaded}
          alt={alt ?? 'content-image'}
          title={alt ?? ''}
          onError={this.setDefaultSrc}
          style={style}
        />
      )
    //   }
    // </InView>
    return (
      <React.Fragment>
        {content}
        {!isIE && !this.loaded && this.src != null && (
          /*
          <CircularProgress
            className={classes.progress}
            color="secondary"
            size="2rem"
          />
          */
          <div />
        )}
      </React.Fragment>
    )
  }
}

export default withStyles(LazyImageStyles, {
  name: 'LazyImage',
  withTheme: true,
})(LazyImageCls)

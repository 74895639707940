export interface IMemberData {
  id: string
  name: string
  title: string
  img: string
}

export interface ITeamData {
  members: IMemberData[]
}

export const TeamData: ITeamData = require('./team_data.json')

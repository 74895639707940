import * as React from 'react'
import classnames from 'clsx'

import { MountainsStyles } from './mountains.styles'
import { observer } from 'mobx-react'

import black from '../resources/img/01_Landschaft_schwarz.svg'
import white from '../resources/img/02_Landschaft_weiss.svg'
import lightGrey from '../resources/img/03_Landschaft_hellgrau.svg'
import grey from '../resources/img/04_Landschaft_grau.svg'
import { WithStyles, withStyles } from '@material-ui/styles'
import { windowStore } from '../stores/windowStore'
import { computed } from 'mobx'
import { breakpoints } from '../stores/breakpointsStore'

// const blackMobile = require('../resources/img/01_Landschaft_schwarz_mobile.svg')
// const whiteMobile = require('../resources/img/02_Landschaft_weiss_mobile.svg')
// const lightGreyMobile = require('../resources/img/03_Landschaft_hellgrau_mobile.svg')
// const greyMobile = require('../resources/img/04_Landschaft_grau_mobile.svg')

interface IProps {
  scrollPos?: number
  className?: string
  move?: boolean
}

type StyleProps = WithStyles<typeof MountainsStyles>

@observer
class Mountains extends React.Component<IProps & StyleProps> {
  // readonly ww: number = windowStore.width

  // readonly mountains = [
  //   { id: 'greyBg', speedX: this.ww / 108, speedY: 25, scaleSpeed: 4, className: styles.bgGrey, classNameAnimated: styles.bgGreyAnimated, backgroundImage: grey },
  //   { id: 'lightGreyBg', speedX: this.ww / 96, speedY: 50, scaleSpeed: 8, className: styles.bgLightGrey, classNameAnimated: styles.bgLightGreyAnimated, backgroundImage: lightGrey },
  //   { id: 'whiteBg', speedX: this.ww / 72, speedY: 75, scaleSpeed: 16, className: styles.bgWhite, classNameAnimated: styles.bgWhiteAnimated, backgroundImage: white },
  //   { id: 'blackBg', speedX: this.ww / 36, speedY: 100, scaleSpeed: 32, className: styles.bgBlack, classNameAnimated: styles.bgBlackAnimated, backgroundImage: black }
  // ]

  @computed
  get isPortrait() {
    return windowStore.height > windowStore.width
  }

  render() {
    const { classes, move } = this.props
    // const scroll = 0

    const ww: number = windowStore.width
    const mountains = [
      {
        id: 'greyBg',
        speedX: ww / 108,
        speedY: 25,
        scaleSpeed: 4,
        className: classes.bgGrey,
        classNameAnimated: classes.bgGreyAnimated,
        backgroundImage: grey,
      },
      {
        id: 'lightGreyBg',
        speedX: ww / 96,
        speedY: 50,
        scaleSpeed: 8,
        className: classes.bgLightGrey,
        classNameAnimated: classes.bgLightGreyAnimated,
        backgroundImage: lightGrey,
      },
      {
        id: 'whiteBg',
        speedX: ww / 72,
        speedY: 75,
        scaleSpeed: 16,
        className: classes.bgWhite,
        classNameAnimated: classes.bgWhiteAnimated,
        backgroundImage: white,
      },
      {
        id: 'blackBg',
        speedX: ww / 36,
        speedY: 100,
        scaleSpeed: 32,
        className: classes.bgBlack,
        classNameAnimated: classes.bgBlackAnimated,
        backgroundImage: black,
      },
    ]

    // let move = true
    // if (this.props.scrollPos && this.props.scrollPos >= 0 && this.props.scrollPos <= 1) {
    //   move = true
    // } else if (this.props.scrollPos && this.props.scrollPos < 0 && this.props.scrollPos > 1) {
    //   move = false
    // }

    const mnts: JSX.Element[] = []
    for (const mountain of mountains) {
      mnts.push(
        <div
          className={classnames(mountain.className, mountain.classNameAnimated)}
          key={mountain.className}
          style={{
            /*
          backgroundImage: `url(${mountain.backgroundImage})`,
          transform: `translateY(${-scroll * mountain.speedY}px)`,
          bottom: `${scroll >= 0 ? scroll * mountain.speedY : 0}px`,
          animation: `${mountain.id} ${this.store.storeCommon.window.width / 50 }s linear infinite`
          */
            animationDuration: `${ww / 8}s`,
            WebkitAnimationDuration: `${ww / 8}s`,
            animationPlayState: move ? 'running' : 'paused',
          }}
        >
          <img
            src={mountain.backgroundImage}
            alt="mountain"
            /* 
              style={{transform: `translateY(${scroll >= 0 ? -scroll * mountain.speedY : 0}px)`}}
            */
          />
        </div>
      )
    }

    let background: JSX.Element | null = null
    background = (
      <div
        className={classnames(this.props.className, classes.background)}
        style={{
          width: 2 * windowStore.width,
          transform: `scaleY(${
            this.isPortrait ? 1 : (1.2 * windowStore.height) / windowStore.width
          })`,
          height:
            (Boolean(breakpoints.desktop) ? 0.3 : 0.45) * windowStore.width,
          bottom: Boolean(breakpoints.desktop) ? 0 : 0.05 * windowStore.height,
        }}
      >
        {mnts}
      </div>
    )
    return background
  }
}

export default withStyles(MountainsStyles, { name: 'Mountains' })(Mountains)

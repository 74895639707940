import { MOBILE, DESKTOP } from '../utils/styles'
import { createStyles } from '@material-ui/styles'

export const MountainsStyles = createStyles({
  background: {
    position: 'absolute',
    zIndex: -20,
    left: 0,
    [`@media ${DESKTOP}`]: {
      transformOriginY: 'bottom',
      '@media (orientation: portrait)': {
        bottom: '50px !important',
      },
    },
    '& $bgBlack, $bgWhite, $bgLightGrey, $bgGrey': {
      width: '100%',
      height: '100%',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'left',
      position: 'absolute',
      transformOrigin: 'bottom',
      /* bottom: 0; */
      left: 0,
      /* z-index: -10; */
      '& > img': {
        height: '100%',
      },
    },
  },
  bgBlack: {
    marginBottom: '-4px',
    // bottom: '-4rem',
    bottom: 0,
    height: '56%',
    [`@media ${DESKTOP}`]: {
      '@media (orientation: portrait)': {
        // bottom: '-3rem',
        bottom: '-1rem',
      },
    },
    [`@media ${MOBILE}`]: {
      // bottom: '-2rem',
      bottom: 0,
    },
  },
  bgBlackAnimated: {
    animation: '$blackBg linear infinite',
    WebkitAnimation: '$blackBg linear infinite',
    MozAnimation: '$blackBg linear infinite',
    MsAnimation: '$blackBg linear infinite',
    OAnimation: '$blackBg linear infinite',
    [`@media ${MOBILE}`]: {
      animation: '$blackBgMobile linear infinite',
      WebkitAnimation: '$blackBgMobile linear infinite',
      MozAnimation: '$blackBgMobile linear infinite',
      MsAnimation: '$blackBgMobile linear infinite',
      OAnimation: '$blackBgMobile linear infinite',
    },
  },
  bgWhite: {
    // bottom: '-1vh',
    bottom: 0,
    height: '80%',
    [`@media ${MOBILE}`]: {
      bottom: 0,
    },
  },

  bgWhiteAnimated: {
    animation: '$whiteBg linear infinite',
    WebkitAnimation: '$whiteBg linear infinite',
    MozAnimation: '$whiteBg linear infinite',
    MsAnimation: '$whiteBg linear infinite',
    OAnimation: '$whiteBg linear infinite',
  },
  bgLightGrey: {
    // bottom: '2vh',
    bottom: 0,
    height: '96%',
  },
  bgLightGreyAnimated: {
    animation: '$lightGreyBg linear infinite',
    WebkitAnimation: '$lightGreyBg linear infinite',
    MozAnimation: '$lightGreyBg linear infinite',
    MsAnimation: '$lightGreyBg linear infinite',
    OAnimation: '$lightGreyBg linear infinite',
  },
  bgGrey: {
    /* animation: greyBg linear infinite; */
    // bottom: '3vh',
    bottom: '15%',
  },
  bgGreyAnimated: {
    animation: '$greyBg linear infinite',
    WebkitAnimation: '$greyBg linear infinite',
    MozAnimation: '$greyBg linear infinite',
    MsAnimation: '$greyBg linear infinite',
    OAnimation: '$greyBg linear infinite',
  },
  '@keyframes blackBg': {
    '0%': { transform: 'translateX(0) scaleY(0.6)' },
    '50%': { transform: 'translateX(-100vw) rotate(0.02deg) scaleY(0.6)' },
    '100%': { transform: 'translateX(0) rotate(0deg) scaleY(0.6)' },
  },
  '@keyframes blackBgMobile': {
    '0%': { transform: 'translateX(0) scaleY(0.65)' },
    '50%': { transform: 'translateX(-100vw) rotate(0.02deg) scaleY(0.65)' },
    '100%': { transform: 'translateX(0) rotate(0deg) scaleY(0.65)' },
  },

  // '@-webkit-keyframes blackBg': {
  //   '0%': {transform: 'translateX(0)'},
  //   '50%': {transform: 'translateX(-100vw) rotate(0.02deg)'},
  //   '100%': {transform: 'translateX(0) rotate(0deg)'}
  // },

  // '@-moz-keyframes blackBg': {
  //   '0%': {transform: 'translateX(0)'},
  //   '50%': {transform: 'translateX(-100vw) rotate(0.02deg)'},
  //   '100%': {transform: 'translateX(0) rotate(0deg)'}
  // },

  // '@-o-keyframes blackBg': {
  //   '0%': {transform: 'translateX(0)'},
  //   '50%': {transform: 'translateX(-100vw) rotate(0.02deg)'},
  //   '100%': {transform: 'translateX(0) rotate(0deg)'}
  // },

  '@keyframes whiteBg': {
    '0%': { transform: 'translateX(0) scaleY(0.75)' },
    '50%': { transform: 'translateX(-50vw) scaleY(0.75)' },
    '100%': { transform: 'translateX(0) scaleY(0.75)' },
  },

  // '@-webkit-keyframes whiteBg': {
  //   '0%': {transform: 'translateX(0)'},
  //   '50%': {transform: 'translateX(-50vw)'},
  //   '100%': {transform: 'translateX(0)'}
  // },

  // '@-moz-keyframes whiteBg': {
  //   '0%': {transform: 'translateX(0)'},
  //   '50%': {transform: 'translateX(-50vw)'},
  //   '100%': {transform: 'translateX(0)'}
  // },

  // '@-o-keyframes whiteBg': {
  //   '0%': {transform: 'translateX(0)'},
  //   '50%': {transform: 'translateX(-50vw)'},
  //   '100%': {transform: 'translateX(0)'}
  // },

  '@keyframes lightGreyBg': {
    '0%': { transform: 'translateX(0)' },
    '50%': { transform: 'translateX(-25vw)' },
    '100%': { transform: 'translateX(0)' },
  },
  // '@-webkit-keyframes lightGreyBg': {
  //   '0%': {transform: 'translateX(0)'},
  //   '50%': {transform: 'translateX(-25vw)'},
  //   '100%': {transform: 'translateX(0)'}
  // },

  // '@-moz-keyframes lightGreyBg': {
  //   '0%': {transform: 'translateX(0)'},
  //   '50%': {transform: 'translateX(-25vw)'},
  //   '100%': {transform: 'translateX(0)'}
  // },

  // '@-o-keyframes lightGreyBg': {
  //   '0%': {transform: 'translateX(0)'},
  //   '50%': {transform: 'translateX(-25vw)'},
  //   '100%': {transform: 'translateX(0)'}
  // },

  '@keyframes greyBg': {
    '0%': { transform: 'translateX(0)' },
    '50%': { transform: 'translateX(-12vw)' },
    '100%': { transform: 'translateX(0)' },
  },

  // '@-webkit-keyframes greyBg': {
  //   '0%': {transform: 'translateX(0)'},
  //   '50%': {transform: 'translateX(-12vw)'},
  //   '100%': {transform: 'translateX(0)'}
  // },

  // '@-moz-keyframes greyBg': {
  //   '0%': {transform: 'translateX(0)'},
  //   '50%': {transform: 'translateX(-12vw)'},
  //   '100%': {transform: 'translateX(0)'}
  // },

  // '@-o-keyframes greyBg': {
  //   '0%': {transform: 'translateX(0)'},
  //   '50%': {transform: 'translateX(-12vw)'},
  //   '100%': {transform: 'translateX(0)'}
  // },
})

import { MOBILE, DESKTOP } from '../../utils/styles'
import { createStyles } from '@material-ui/styles'

export const FeaturedProjectsStyles = createStyles({
  container: {
    width: '100%',
    '@global': {
      '.fp-controlArrow': {
        display: 'none',
      },
    },
    [`@media ${MOBILE}`]: {},
    [`@media ${DESKTOP}`]: {},
  },
  slide: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [`@media ${MOBILE}`]: {
      overflow: 'hidden',
    },
  },
  title: {
    textAlign: 'left',
    width: '100%',
    top: 0,
    left: 0,
    [`@media ${DESKTOP}`]: {
      position: 'absolute',
      fontSize: '2rem',
      padding: '10vh 10vw 4vh 10vw',
    },
    [`@media ${MOBILE}`]: {
      padding: '1.65rem 2.5rem 1.25rem 3rem',
      fontSize: '1.125rem',
      lineHeight: 1.4,
    },
  },
  projectsContainer: {
    display: 'flex',
    flex: 1,

    // height: 'calc(100% - 5rem)',

    /* -webkit-overflow-scrolling: touch;  */
    [`@media ${DESKTOP}`]: {
      flexDirection: 'row',
      position: 'absolute',
      top: 0,
      overflow: 'hidden',
    },
    [`@media ${MOBILE}`]: {
      flexDirection: 'column',
      '& > div': {
        height: '100%',
      },
    },
  },
  swipeDots: {
    bottom: '11%',
  },
})

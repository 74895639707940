import * as React from 'react'
import { observer } from 'mobx-react'
import SocialMediaIcon from './socialMediaIcon'
import { WithStyles, withStyles } from '@material-ui/styles'
import { windowStore } from '../stores/windowStore'
// import { StickyContainer } from '../../page/stickyContainer'

import logo from '../resources/icons/Codelane_logo_weiss.svg'
import facebook from '../resources/icons/Icon_Facebook.svg'
import instagram from '../resources/icons/Icon_Insta.svg'
import twitter from '../resources/icons/Icon_twitter.svg'
import { FooterStyles } from './footer.styles'
// const imprint = require('./Icon_Imprint.svg')

// interface IProps {
//   scrollPos?: number
// }

const email = 'hello@codelane.ch'
const subject = 'I want to build something great with you!'

type StyleProps = WithStyles<typeof FooterStyles>

@observer
export class Footer extends React.Component<StyleProps> {
  render() {
    const { classes } = this.props
    return (
      <div
        className={classes.footerBox}
        style={{ height: 0.5 * windowStore.height }}
      >
        <div className={classes.column}>
          <img className={classes.logo} src={logo} alt="logo" />
        </div>
        <div className={classes.column}>
          <div className={classes.address}>
            <p>Code Lane GmbH.</p>
            <p>Technoparkstr. 1</p>
            <p>8005 Zürich</p>
            <a href={`mailto:${email}?subject=${subject}`}>{email}</a>
            {/* <p>www.codelane.ch</p> */}
          </div>
        </div>
        <div className={classes.column}>
          <SocialMediaIcon to="http://fb.me/codelane.ch" src={facebook} />
          <SocialMediaIcon
            to="https://www.instagram.com/codelane.ch/?hl=en"
            src={instagram}
          />
          <SocialMediaIcon to="https://twitter.com/codelane_ch" src={twitter} />
        </div>
      </div>
    )
  }
}

export default withStyles(FooterStyles, { name: 'Footer' })(Footer)

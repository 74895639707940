import { MOBILE, DESKTOP } from '../../utils/styles'
import { createStyles } from '@material-ui/styles'

import nextIcon from '../../resources/icons/next.svg'
import externalLinkIcon from '../../resources/icons/icon-external-link.svg'

export const ProjectsStyles = createStyles({
  projectBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    position: 'relative',
    [`@media ${DESKTOP}`]: {
      justifyContent: 'center',
    },
  },
  swipeDots: {
    bottom: '6%',
  },

  imageBox: {
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    color: 'black',
    '& > img': {
      width: '50%',
      maxWidth: '100%',
      maxHeight: '100%',
      objectFit: 'contain',
    },
    [`@media ${DESKTOP}`]: {
      // position: 'absolute',
      // left: '-85vw',
      // marginTop: 'auto',
      // marginBottom: 'auto',
      flexShrink: 0,
      alignSelf: 'center',
      justifyContent: 'flex-start',
      '& > img': {
        paddingLeft: '10%',
        paddingTop: '1em',
        paddingBottom: '1em',
      },
    },
    [`@media ${MOBILE}`]: {
      height: '35%',
      position: 'relative',
      flexDirection: 'column',
      justifyContent: 'center',
      alignSelf: 'flex-start',
      '@media (max-width: 350px)': {
        height: '30%',
      },
    },
  },
  imageBoxContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '60%',
  },
  image: {
    position: 'relative',
    maxWidth: '100%',
    maxHeight: '100%',

    [`@media ${DESKTOP}`]: {
      width: '60%',
      height: '60%',
      paddingTop: '1em',
      paddingBottom: '1em',
      '& > img': {
        height: 'auto',
        objectFit: 'contain',
        maxHeight: '100%',
      },
      '@media (orientation: portrait)': {
        maxHeight: 250,
      },
    },
    [`@media ${MOBILE}`]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignSelf: 'center',
      width: '50%',
      height: '70%',
      '& > img': {
        // height: 'auto',
        width: 'auto',
      },
    },
  },

  descriptionBox: {
    backgroundColor: '#6769C9',
    [`@media ${DESKTOP}`]: {
      /* width: 20rem;
      height: 27rem; */
      position: 'absolute',
      marginBottom: 'auto',
      flexShrink: 0,
      padding: 30,
      alignSelf: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
    },
    [`@media ${MOBILE}`]: {
      height: '55%',
      bottom: '10%',
      overflow: 'auto',
      /* box-shadow: inset 0px 5px 10px 1px #000000; */
      '@media (max-width: 350px)': {
        height: '60%',
      },
    },
  },

  projectName: {
    color: 'white',
    fontSize: '1.2rem',
    [`@media ${DESKTOP}`]: {
      flex: 1,
    },
  },

  projectCompetence: {
    color: 'white',
    textTransform: 'uppercase',
    fontSize: '0.8rem',
    fontWeight: 700,
  },

  projectDescription: {
    color: 'white',
    fontSize: '0.875rem',
    '& a': {
      // position: 'relative',
      borderBottom: 'rgba(255, 255, 255, 0.6) solid 1px',
      // '&:after': {
      //   content: '""',
      //   position: 'absolute',
      //   bottom: 0,
      //   left: 0,
      //   width: '100%',
      //   height: 1,
      //   backgroundColor: 'rgba(255, 255, 255, 0.6)'
      // }
    },
    [`@media ${MOBILE}`]: {
      '@media (max-width: 350px)': {
        fontSize: '0.75rem',
      },
    },
  },
  projectTags: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    [`@media ${DESKTOP}`]: {
      padding: '20px 0 10px',
    },
    [`@media ${MOBILE}`]: {
      padding: '10px 0',
    },
  },
  digitalHumanButton: {
    position: 'relative',
    // background: 'linear-gradient(90deg, rgba(58,181,161,1) 0%, rgba(102,102,204,1) 75%)',
    fontWeight: 'bold',
    fontSize: '0.725rem',
    lineHeight: 1.25,
    textAlign: 'center',

    boxShadow: 'inset 0 0 20px 20px transparent',
    transition: 'box-shadow 200ms ease',
    animation: '1.75s ease-in-out 0s infinite normal both running $heartbeat',
    [`@media ${DESKTOP}`]: {
      width: '10rem',
      borderRadius: 5,
      backgroundColor: 'rgba(58,181,161,1)',
      marginTop: '0.5rem',
      padding: '0.5rem 1.5rem 0.5rem 0.5rem',
      '&::after': {
        content: '""',
        backgroundImage: `url(${nextIcon})`,
        position: 'absolute',
        right: '0.75rem',
        top: '36%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        height: '0.6rem',
        width: '0.6rem',
      },
    },

    [`@media ${MOBILE}`]: {
      borderRadius: '50%',
      background:
        'linear-gradient(90deg, rgba(58,181,161,1) 0%, rgba(102,102,204,1) 75%)',
      width: '2rem',
      height: '2rem',
      position: 'absolute',
      bottom: '1rem',
      right: '1rem',
      '&::after': {
        content: '""',
        backgroundImage: `url(${externalLinkIcon})`,
        position: 'absolute',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        top: 'calc(50% - 0.45rem)',
        right: 'calc(50% - 0.45rem)',
        width: '0.8rem',
        height: '0.8rem',
      },
    },
    '&:hover': {
      boxShadow: 'inset 0 0 20px 20px rgba(0, 0, 0, 0.1)',
    },
  },
  partners: {},
  '@keyframes heartbeat': {
    '0%': { transform: 'scale(1)', animationTimingFunction: 'ease-out' },
    '10%': { transform: 'scale(0.93)', animationTimingFunction: 'ease-in' },
    '17%': { transform: 'scale(0.98)', animationTimingFunction: 'ease-out' },
    '33%': { transform: 'scale(0.89)', animationTimingFunction: 'ease-in' },
    '45%': { transform: 'scale(1)', animationTimingFunction: 'ease-out' },
  },
})

import React from 'react'
import clsx from 'clsx'
import { observer } from 'mobx-react'
import { WithStyles, withStyles } from '@material-ui/styles'
import { FeaturedProjectsStyles } from './featuredProjects.styles'
import { ProjectsData } from '../../data/projects'
import { Project } from './project'
import ArrowDown from '../../common/arrowDown'
import Section from '../../common/section'
import { breakpoints } from '../../stores/breakpointsStore'
import SwipeableViews from 'react-swipeable-views'
import SwipeDots from '../../common/swipeDots'
import { bind } from 'bind-decorator'
import { action, observable } from 'mobx'
import { windowStore } from '../../stores/windowStore'

const projectsData = ProjectsData

interface FeaturedProjectsProps
  extends WithStyles<typeof FeaturedProjectsStyles> {
  id?: string
}

@observer
class FeaturedProjectsCls extends React.Component<FeaturedProjectsProps> {
  @observable currentIdx: number = 0

  @bind
  @action
  setActiveSlideIdx(idx: number) {
    this.currentIdx = idx
  }

  render() {
    const { classes, id } = this.props
    const projects = []
    for (const i of [7, 0]) {
      projects.push(
        <div
          className={clsx(
            Boolean(breakpoints.desktop) ? 'slide' : undefined,
            classes.slide
          )}
          key={i}
        >
          <Project data={projectsData.projects[i]} />
        </div>
      )
    }
    let content: JSX.Element | null = null
    if (Boolean(breakpoints.desktop)) {
      content = (
        <div
          className={classes.container}
          style={{ height: windowStore.height }}
        >
          <div className={classes.title}>Featured projects</div>
          {projects}
          <ArrowDown />
        </div>
      )
    } else {
      content = (
        <Section title="Featured projects" id={id}>
          <SwipeableViews
            className={classes.projectsContainer}
            style={{ width: windowStore.width }}
            // slideRenderer={(i) => this.slideRenderer(i)}
            // slideCount={projectsData.projects.length}
            onChangeIndex={(i) => {
              this.setActiveSlideIdx(i)
              // commonStore.fancyScroll = false
            }}
          >
            {projects}
          </SwipeableViews>
          <SwipeDots
            count={projects.length}
            activeSlideIdx={this.currentIdx}
            theme="black"
            className={classes.swipeDots}
            scroll={0}
            distanceToArrows="-60vh"
          />
        </Section>
      )
    }
    return content
  }
}

export default withStyles(FeaturedProjectsStyles, {
  name: 'FeaturedProjects',
  withTheme: true,
})(FeaturedProjectsCls)

import * as React from 'react'
import { observer } from 'mobx-react'
import SwipeDots from '../../common/swipeDots'
import { observable, action } from 'mobx'
import { TeamData } from '../../data/team'
import { WithStyles, withStyles } from '@material-ui/styles'
import { TeamStyles } from './team.style'
import { breakpoints } from '../../stores/breakpointsStore'
import { windowStore } from '../../stores/windowStore'
import TeamGrid from '../../common/grid/teamGrid'
import { bind } from 'bind-decorator'
import Section from '../../common/section'
import { SlideRendererParams, virtualize } from 'react-swipeable-views-utils'
import SwipeableViews from 'react-swipeable-views'
const SwipeableViewsVirtual = virtualize(SwipeableViews)

const teamData = TeamData

type StyleProps = WithStyles<typeof TeamStyles>

interface ITeamProps extends StyleProps {
  id?: string
}

interface IFounderProps {
  founder: any
}

@observer
class Team extends React.Component<ITeamProps> {
  @observable activeSlideIdx = 0

  static slideScrollHeight() {
    return Boolean(breakpoints.desktop) ? 120 : 150
  }

  slideRenderer(param: SlideRendererParams): JSX.Element {
    return (
      <TeamGrid
        key={param.index}
        data={teamData.members.slice(param.index * 6, (param.index + 1) * 6)}
      />
    )
  }

  @bind
  @action
  setActiveSlideIdx(idx: number) {
    this.activeSlideIdx = idx
  }

  render() {
    const { classes } = this.props
    const scroll = 0
    const columns = []
    for (let i = 0; i < 2; i++) {
      columns.push(
        <TeamGrid key={i} data={teamData.members.slice(i * 6, (i + 1) * 6)} />
      )
    }

    let content: JSX.Element | null = null
    if (Boolean(breakpoints.phone)) {
      content = (
        <SwipeableViewsVirtual
          className={classes.columnsBox}
          slideRenderer={(i) => this.slideRenderer(i)}
          slideCount={Math.ceil(teamData.members.length / 6)}
          onSwitching={(i) => {
            this.setActiveSlideIdx(i)
          }}
          style={{
            height: `calc(${windowStore.height}px - 30%)`,
            width: windowStore.width,
            marginTop: Boolean(breakpoints.desktop)
              ? 0.04 * windowStore.height
              : undefined,
            paddingTop: Boolean(breakpoints.desktop)
              ? 'auto'
              : 0.04 * windowStore.height,
          }}
        ></SwipeableViewsVirtual>
      )
    } else {
      content = <TeamGrid data={teamData.members} />
    }

    return (
      <Section title="Our team" id="team">
        {content}
        {Boolean(breakpoints.phone) && (
          <SwipeDots
            count={Math.ceil(teamData.members.length / 6)}
            activeSlideIdx={this.activeSlideIdx}
            scroll={scroll}
          />
        )}
      </Section>
    )
  }
}

export default withStyles(TeamStyles, { name: 'Team' })(Team)

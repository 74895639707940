import { createStyles } from '@material-ui/styles'
import { DESKTOP, MOBILE } from '../utils/styles'

export const SwipeDotsStyles = createStyles({
  dots: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',

    position: 'absolute',
    bottom: '8vh',
    left: 0,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
  },

  dot: {
    opacity: 0.3,
    borderRadius: '50%',
    [`@media ${DESKTOP}`]: {
      height: 12,
      width: 12,
      marginLeft: 5,
      marginRight: 5,
    },
    [`@media ${MOBILE}`]: {
      height: 10,
      width: 10,
      marginLeft: 4,
      marginRight: 4,
    },
  },

  dotWhite: {
    opacity: 1,
  },

  arrows: {
    '& i': {
      borderStyle: 'solid',
      borderColor: 'white',
      borderWidth: '0 2px 2px 0',
      display: 'inline-block',
      padding: 7,
      opacity: 0,
    },
  },

  animatedArrow: {
    animation: '$bounce 40s forwards',
  },

  left: {
    position: 'absolute',
    top: 0,
    left: '4vw',
  },

  right: {
    position: 'absolute',
    top: 0,
    right: '4vw',
  },

  rightArrow: {
    transform: 'rotate(-45deg)',
    WebkitTransform: 'rotate(-45deg)',
  },

  leftArrow: {
    transform: 'rotate(135deg)',
    WebkitTransform: 'rotate(135deg)',
  },

  '@keyframes bounce': {
    '0%': { opacity: 0 },
    '25%': { opacity: 0.7 },
    '50%': { opacity: 0 },
    '75%': { opacity: 0.7 },
    '100%': { opacity: 0 },
  },
})

export function generateMobileLayout (length: number) {
  const gridLayout: any[] = []

  for (let i = 0; i < length; i++) {
    if (!(i === length - 1 && i % 2 === 0)) {
      gridLayout.push(
        { i: String(i), x: i % 2 === 0 ? 0 : 1, y: Math.floor(i / 2), w: 1, h: 1, static: true }
      )
    } else {
      gridLayout.push(
        { i: String(i), x: 0.5, y: Math.floor(i / 2), w: 1, h: 1, static: true }
      )
    }

  }

  // console.log(gridLayout)

  return gridLayout
}

import React from 'react'
import { AppStyles } from './App.styles'
import { Switch, Route } from 'react-router-dom'
import { observer } from 'mobx-react'
import { WithStyles, withStyles } from '@material-ui/styles'
import Page from './page/Page'
import { MatchMediaProvider } from 'mobx-react-matchmedia'
import { breakpoints } from './stores/breakpointsStore'
import ProjectsFullpage from './page/projects/projectsFullpage'
import Jobs from './Jobs'

type StyleProps = WithStyles<typeof AppStyles>

@observer
class App extends React.Component<StyleProps> {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route exact path="/projects" component={ProjectsFullpage} />
          <Route exact path="/jobs" component={Jobs} />
          <Route component={Page} />
        </Switch>
        <MatchMediaProvider breakpoints={breakpoints} />
      </React.Fragment>
    )
  }
}

export default withStyles(AppStyles)(App)

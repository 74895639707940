import { createStyles } from '@material-ui/styles'

export const LazyImageStyles = createStyles({
  '@global': {
    '.lazyload, .lazyloading': {
      opacity: 0,
    },
    '.lazyloaded': {
      opacity: 1,
      transition: 'opacity 250ms ease-in',
    },
    'img.ls-blur-up-is-loading, img.lazyload:not([src])': {
      visibility: 'hidden',
    },

    '.ls-blur-up-img': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'block',
      fontFamily: 'blur-up: always", "object-fit: cover',
      objectFit: 'cover',
      filter: 'blur(10px)',
      opacity: 1,
      transition: 'opacity 1000ms, filter 1500ms',
    },

    '.ls-blur-up-img.ls-inview.ls-original-loaded': {
      opacity: 0,
      // filter: 'blur(5px)',
    },
  },
  container: {
    display: 'block',
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    // opacity: 0,
    position: 'absolute',
    top: '50% !important',
    left: '50% !important',
    transform: 'translate(-50%, -50%) scale(1.01)',
    fontFamily: 'blur-up: always", "object-fit: cover',
  },
  progress: {
    position: 'absolute',
    top: 'calc(50% - 1rem) !important',
    left: 'calc(50% - 1rem) !important',
    color: '#0360aa',
  },
  loaded: {
    opacity: 1,
    animation: '$fadeIn 300ms ease-in forwards',
  },
  bgImage: {
    // opacity: 0,
    position: 'absolute',
    top: '50% !important',
    left: '50% !important',
    transform: 'translate(-50%, -50%) scale(1.01)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '100%',
  },
  '@keyframes fadeIn': {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },
  'ken-burns': {
    top: '0% !important',
    left: '0% !important',
    animation: '$move 40s ease infinite alternate',
    /* Add infinite to loop. */

    '-ms-animation': '$move 40s ease infinite alternate',
    // -webkit-animation: move 40s ease;
    // -0-animation: move 40s ease;
    // -moz-animation: move 40s ease;
    // position: absolute;
  },
  '@keyframes move': {
    '0%': {
      // -webkit-transform-origin: bottom left;
      // -moz-transform-origin: bottom left;
      // -ms-transform-origin: bottom left;
      // -o-transform-origin: bottom left;
      transformOrigin: 'bottom left',
      transform: 'scale(1.0)',
      '-ms-transform': 'scale(1.0)',
      /* IE 9 */

      // -webkit-transform: scale(1.0);
      /* Safari and Chrome */

      // -o-transform: scale(1.0);
      /* Opera */

      // -moz-transform: scale(1.0);
      /* Firefox */
    },
    '100%': {
      transform: 'scale(1.2)',
      '-ms-transform': 'scale(1.2)',
      /* IE 9 */

      // -webkit-transform: scale(1.2);
      /* Safari and Chrome */

      // -o-transform: scale(1.2);
      /* Opera */

      // -moz-transform: scale(1.2);
      /* Firefox */
    },
  },
})

import * as React from 'react'
import { observer } from 'mobx-react'
import ProjectTag from './projectTag'
import { withStyles, WithStyles } from '@material-ui/styles'
import { ProjectsStyles } from './project.styles'
import { IProjectData } from '../../data/projects'
import { breakpoints } from '../../stores/breakpointsStore'
import LazyImage from '../../common/lazyImage/lazyImage'
import { windowStore } from '../../stores/windowStore'

type StyleProps = WithStyles<typeof ProjectsStyles>

interface IProjectProps {
  data: IProjectData
}

@observer
class ProjectCls extends React.Component<IProjectProps & StyleProps> {
  tryRequire = (path: string) => {
    try {
      return require(`${path}`)
    } catch (err) {
      return null
    }
  }

  render() {
    const { classes } = this.props
    const project = this.props.data
    const tags = []
    for (const tag of project.tags) {
      tags.push(<ProjectTag tag={tag} key={tag} />)
    }

    let projectButton: JSX.Element | null = null
    if (project.url != null) {
      projectButton = (
        <a
          id={project.id}
          className={classes.digitalHumanButton}
          href={project.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {Boolean(breakpoints.desktop) && <span>Explore now</span>}
        </a>
      )
    }

    const description: JSX.Element = (
      <div className={classes.projectDescription}>{project.description}</div>
    )

    let partners: JSX.Element | null = null
    if (project.partners) {
      partners = (
        <div
          className={classes.projectDescription}
          style={
            Boolean(breakpoints.phone)
              ? {
                  margin: `${0.01 * windowStore.height}px 0 ${
                    0.03 * windowStore.height
                  }px 0`,
                }
              : { marginTop: 0.04 * windowStore.height }
          }
        >
          <strong>Partners: </strong>
          {project.partners.map((p) => (
            <a
              key={p.name}
              href={p.url}
              target="_blank"
              rel="noreferrer noopener"
            >
              {p.name}
            </a>
          ))}
        </div>
      )
    }

    return (
      <div
        className={classes.projectBox}
        key={project.name}
        style={
          Boolean(breakpoints.desktop)
            ? { minHeight: windowStore.height }
            : {
                minHeight: `calc(${windowStore.height}px - 6rem - 1px)`,
                height: `calc(${windowStore.height}px - 6rem - 1px)`,
              }
        }
      >
        <div
          className={classes.imageBox}
          style={{
            height: Boolean(breakpoints.desktop)
              ? 0.56 * windowStore.height
              : undefined,
            width:
              (Boolean(breakpoints.desktop) ? 0.65 : 1) * windowStore.width,
          }}
        >
          <div className={classes.imageBoxContent}>
            <div className={classes.image}>
              <LazyImage
                src={this.tryRequire(`./media/${project.img}`)}
                srcFallback={
                  project.imgFallback != null
                    ? this.tryRequire(`./media/${project.imgFallback}`)
                    : undefined
                }
                alt={project.description}
              />
            </div>
            {/* <img src={require(`./media/${project.img}`)} alt="project" /> */}
            {projectButton}
          </div>
        </div>
        <div
          className={classes.descriptionBox}
          key={project.id}
          style={{
            width: Boolean(breakpoints.desktop)
              ? 0.33 * windowStore.width
              : '100%',
            height: Boolean(breakpoints.desktop)
              ? 0.57 * windowStore.height
              : undefined,
            right: Boolean(breakpoints.desktop)
              ? 0.12 * windowStore.width
              : 'auto',
            top: Boolean(breakpoints.desktop)
              ? 0.27 * windowStore.height
              : 'auto',
            padding: Boolean(breakpoints.desktop)
              ? 'auto'
              : `${0.03 * windowStore.height}px ${
                  0.05 * windowStore.width
                }px 0`,
          }}
        >
          <div className={classes.projectName}>{project.name}</div>
          <div
            className={classes.projectCompetence}
            style={{ marginTop: 0.01 * windowStore.height }}
          >
            {project.competence}
          </div>
          {description}
          {partners}
          <div className={classes.projectTags}>{tags}</div>
        </div>
      </div>
    )
  }
}

export const Project = withStyles(ProjectsStyles, {
  name: 'Project',
})(ProjectCls)

import React from 'react'
import { observer } from 'mobx-react'
import { WithStyles, withStyles } from '@material-ui/styles'
import { ProjectsFullpageStyles } from './projectsFullpage.styles'
import { Link } from 'react-router-dom'
import ReactFullpage from '@fullpage/react-fullpage'
import clsx from 'clsx'
import { Project } from './project'
import { ProjectsData, IProjectData } from '../../data/projects'
import { observable, action, computed, runInAction } from 'mobx'
import bind from 'bind-decorator'
import _ from 'lodash'
import { windowStore } from '../../stores/windowStore'
import { breakpoints } from '../../stores/breakpointsStore'
import iconClose from '../../resources/icons/icon-close.svg'
import arrowBack from '../../resources/icons/arrow-back.svg'
import {
  virtualize,
  bindKeyboard,
  SlideRendererParams,
} from 'react-swipeable-views-utils'
import SwipeableViews from 'react-swipeable-views'
import SwipeDots from '../../common/swipeDots'
import { capitalizeFirstLetter } from '../../utils/etcUtils'
import ArrowDown from '../../common/arrowDown'
import { routerStore } from '../../stores/routerStore'
import { Portal } from 'react-portal'
import { enableScrollTimeout } from '../../utils/constants'

const SwipeableViewsVirtual = bindKeyboard(virtualize(SwipeableViews))

const projectsData = ProjectsData

type projectType =
  | 'mobile-and-web-apps'
  | 'video-analytics-and-3d-graphics'
  | 'machine-learning'

const projectTypes: any[] = [
  { id: 'app+web', name: 'mobile-and-web-apps' },
  { id: 'video+3d', name: 'video-analytics-and-3d-graphics' },
  { id: 'ml', name: 'machine-learning' },
]

interface MatchParams {
  type: projectType
}

type ProjectsFullpageProps = WithStyles<typeof ProjectsFullpageStyles>

@observer
class ProjectsFullpageCls extends React.Component<ProjectsFullpageProps> {
  @observable dir: 'up' | 'down' = 'down'
  @observable activeType: any = projectTypes[0]
  @observable currentIdx: number = 0
  @observable autoScrolling: boolean = true
  @observable fullpageApi: any | null = null

  componentDidMount() {
    const w = window as any
    runInAction(() => {
      if (w.fullpage_api) {
        this.fullpageApi = w.fullpage_api
      }
    })
  }

  slideRenderer(param: SlideRendererParams, typeData: any): JSX.Element {
    if (param.index >= 0 && param.index < typeData.length) {
      const project = typeData[param.index]
      return <Project key={project.id} data={project} />
    }
    return <div key={Math.random()}></div>
  }

  @computed
  get activeTypeData() {
    return _.filter(projectsData.projects, (p) =>
      p.projectTypes.includes(this.activeType.id)
    )
  }

  @bind
  @action
  setActiveSlideIdx(idx: number) {
    this.currentIdx = idx
  }

  @bind
  @action
  setActiveType(type: any) {
    this.activeType = type
  }

  @bind
  @action
  setDirection(dir: 'up' | 'down') {
    this.dir = dir
  }

  @computed
  get sensitivity() {
    return windowStore.height * 0.05
  }

  getTitle(id?: string) {
    let title = id?.split('-').join(' ')
    if (title) title = capitalizeFirstLetter(title)
    return title
  }

  @computed
  get inProjects() {
    return routerStore.location.pathname.startsWith('/projects')
  }

  @bind
  @action
  handleScroll(delta: number, count: number) {
    console.log(delta, count)

    const activeType = _.find(projectTypes, (t) => t.id === this.activeType.id)
    if (activeType != null) {
      if (this.dir === 'up' && this.currentIdx === activeType.projects[0]) {
        this.autoScrolling = true
        if (activeType.projects.length - 1 === this.currentIdx) return
      } else if (
        this.dir === 'down' &&
        this.currentIdx === activeType.projects[activeType.projects.length - 2]
      ) {
        this.autoScrolling = true
        if (activeType.projects.length - 1 === this.currentIdx) return
      } else {
        this.autoScrolling = false
      }
    }
    console.log('AUTOSCROLL', this.autoScrolling)
    if (Math.abs(delta) < this.sensitivity) {
      if (delta > 0) {
        // if (this.currentIdx !== count - 1)
        this.currentIdx = this.currentIdx + 1
        // this.currentIdx = this.currentIdx === this.childrenCount - 1 ? 0 : this.currentIdx + 1
      } else if (delta < 0) {
        // if (this.currentIdx !== 0)
        this.currentIdx = this.currentIdx - 1
        // this.currentIdx = this.currentIdx === 0 ? this.childrenCount - 1 : this.currentIdx - 1
      }
    }
  }

  @bind
  @action
  handleSlideLeave(
    anchorLink: any,
    index: any,
    slideAnchor: any,
    slideIndex: any
  ) {
    if (this.fullpageApi != null) {
      this.fullpageApi.setAllowScrolling(false)
      setTimeout(() => {
        this.fullpageApi.setAllowScrolling(true)
      }, enableScrollTimeout)
    }
  }

  render() {
    // console.log(this.currentIdx, this.activeType)
    const { classes } = this.props
    const sections: JSX.Element[] = []
    if (projectTypes.length > 0) {
      for (let i = 0; i < projectTypes.length; i++) {
        const isLast: boolean = i === projectTypes.length - 1
        const type = projectTypes[i]
        // const active: boolean = type.id === this.activeType.id
        const typeData = _.filter(projectsData.projects, (p) =>
          p.projectTypes.includes(type.id)
        )
        let projectsOfType = []
        if (projectsData.projects) {
          for (let i = 0; i < typeData.length; i++) {
            const project: IProjectData = typeData[i]
            projectsOfType.push(<Project key={project.id} data={project} />)
          }
        }
        const title = this.getTitle(type.name)
        let projectsSection: JSX.Element | null = null
        // if (breakpoints.desktop) {
        //   projectsSection = <ScrollPage
        //     dir={this.dir}
        //     title={title}
        //     currentIdx={this.currentIdx}
        //     handleScroll={this.handleScroll}
        //     active={active}
        //     >
        //       {projectsOfType}
        //   </ScrollPage>
        // } else {
        projectsSection = (
          <>
            <div className={classes.title}>{title}</div>
            <SwipeableViewsVirtual
              enableMouseEvents
              resistance
              className={classes.projectsContainer}
              slideRenderer={(i: any) => this.slideRenderer(i, typeData)}
              slideCount={projectsOfType.length}
              onChangeIndex={(i: number) => {
                this.setActiveSlideIdx(i)
                // commonStore.fancyScroll = false
              }}
              style={{ width: windowStore.width }}
              // onTransitionEnd={() => (commonStore.fancyScroll = true)}
            />
            <SwipeDots
              count={projectsOfType.length}
              activeSlideIdx={this.currentIdx}
              theme={Boolean(breakpoints.desktop) ? 'white' : 'black'}
              className={classes.swipeDots}
              scroll={0}
              distanceToArrows={
                Boolean(breakpoints.desktop) ? undefined : '-60vh'
              }
            />
          </>
        )
        // }
        sections.push(
          <div
            data-anchor={type.id}
            className={clsx('section', classes.section)}
            key={type.id}
            style={{ minHeight: windowStore.height }}
          >
            {projectsSection}
            {!isLast && <ArrowDown />}
          </div>
        )
        projectsOfType = []
      }
    }

    return (
      <ReactFullpage
        //fullpage options
        // pluginWrapper={pluginWrapper}
        // animateAnchor={false}
        // fixedElements={this.inProjects ? ['#header'] : undefined}
        anchors={projectTypes.map((t) => t.id)}
        licenseKey={'39614BC3-00294F80-B8B6ECF0-428BB41B'}
        autoScrolling={this.autoScrolling}
        touchSensitivity={Boolean(breakpoints.phone) ? 5 : 10}
        afterSlideLoad={this.handleSlideLeave}
        recordHistory={false}
        onLeave={(origin: any, destination: any, direction: any) => {
          this.setActiveType(projectTypes[destination.index])
          this.setDirection(direction)
        }}
        // sectionsColor={['#f2f2f2', '#4BBFC3', '#7BAABE', 'whitesmoke', '#000']}
        render={({ state, fullpageApi }: any) => {
          return (
            <>
              <Portal>
                <Link
                  id={'header'}
                  to="/#competences"
                  className={classes.arrowDown}
                >
                  <img
                    src={Boolean(breakpoints.desktop) ? iconClose : arrowBack}
                    alt="back-button"
                  />
                </Link>
              </Portal>
              {sections}
            </>
          )
        }}
      />
    )
  }
}

export default withStyles(ProjectsFullpageStyles, {
  name: 'ProjectsFullpage',
  withTheme: true,
})(ProjectsFullpageCls)

import * as React from 'react'
import { observer } from 'mobx-react'
// import Footer from '../../common/footer'
import { HiringStyles } from './hiring.styles'
import { WithStyles, withStyles } from '@material-ui/styles'
import { breakpoints } from '../../stores/breakpointsStore'
import { windowStore } from '../../stores/windowStore'
import Mountains from '../../common/mountains'
import Section from '../../common/section'
import hiringImage from '../../resources/img/Illu_Hiring.svg'
import { computed } from 'mobx'

const contentData = [
  {
    title: 'Web Fullstack',
    text:
      'You want to work on all aspects of web development, app development or server infrastructure? Apply now!',
  },
  {
    title: 'Engineering',
    text:
      'You are deep into C++, Python, Computer Graphics or Machine learning? Apply now!',
  },
  {
    title: 'Art and Design',
    text: 'You are a design professional and/or a 3D artist? Apply now!',
  },
  {
    title: 'Ice Cream Fanatic',
    text: 'You like our free ice cream? Apply now and be happy forever!',
  },
  {
    title: '',
    text: '',
    href: '/jobs',
  },
]

type StyleProps = WithStyles<typeof HiringStyles>

interface IHiringProps extends StyleProps {
  id?: string
  active?: boolean
}

@observer
class Hiring extends React.Component<IHiringProps> {
  static slideScrollHeight() {
    return Boolean(breakpoints.phone) ? 250 : 150
  }

  static snapPoints = [0, 1]

  isRender() {
    return true
  }

  @computed
  get isPortrait() {
    return windowStore.width < windowStore.height
  }

  render() {
    const { classes } = this.props
    const scroll = 0

    const checkJobsButton: JSX.Element = (
      <div
        className={classes.checkJobsButton}
        style={
          Boolean(breakpoints.desktop)
            ? { maxWidth: 0.15 * windowStore.width }
            : undefined
        }
      >
        Check jobs
      </div>
    )

    if (this.isRender()) {
      const texts = []
      for (const contentEntry of contentData) {
        texts.push(
          <div className={classes.contentBox} key={contentEntry.title}>
            <div className={classes.contentTitle}>{contentEntry.title}</div>
            <div className={classes.contentText}>{contentEntry.text}</div>
            {contentEntry.href != null && (
              <div className={classes.email}>
                <a
                  href={contentEntry.href}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {checkJobsButton}
                </a>
              </div>
            )}
          </div>
        )
      }

      let hiringBox: JSX.Element | null = null
      if (Boolean(breakpoints.desktop)) {
        hiringBox = (
          <div className={classes.hiringBox}>
            <div className={classes.content}>{texts}</div>
            <div className={classes.image}>
              <img src={hiringImage} alt="hiring" />
            </div>
          </div>
        )
      } else {
        hiringBox = (
          <div className={classes.hiringBox}>
            <div
              className={classes.image}
              style={{ height: 0.5 * windowStore.height }}
            >
              <img src={hiringImage} alt="hiring" />
            </div>
            <div className={classes.content}>{texts[texts.length - 1]}</div>
          </div>
        )
      }

      return (
        <Section id={this.props.id} title="We are hiring!">
          {hiringBox}
          <div
            className={classes.mountainsBox}
            style={{
              transform: `translateY(${
                (Boolean(breakpoints.desktop) ? 0 : 0.4) * windowStore.height
              }px)`,
            }}
          >
            <Mountains scrollPos={scroll} move={true} />
          </div>
          <div
            className={classes.footer}
            style={{
              height: Boolean(breakpoints.phone)
                ? windowStore.height
                : windowStore.height * 0.5,
              marginTop: 0.15 * windowStore.width,
            }}
          >
            {/* <Footer /> */}
          </div>
        </Section>
      )
    } else {
      return <div></div>
    }
  }
}

export default withStyles(HiringStyles, { name: 'Hiring' })(Hiring)

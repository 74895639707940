import { MOBILE, DESKTOP } from '../../utils/styles'
import { createStyles } from '@material-ui/styles'

export const TeamGridStyles = createStyles({
  gridContainer: {
    width: '100%',
    height: '100%',
    /* color: white; */
    display: 'flex',
    WebkitOverflowScrolling: 'touch',
    [`@media ${DESKTOP}`]: {
      justifyContent: 'center',
      /* margin: 0 10vw 14vh 10vw; */
    },
    [`@media ${MOBILE}`]: {
      /* padding: 0 10vw 2vh 10vw; */
      /* padding: 0 20px; */
      /* overflow-y: auto; */
      overflowY: 'hidden',
      overflowX: 'hidden',
    },
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },

  gridItem: {
    height: '100%',
    width: '100%',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transform: 'scale(1)',
    transition: 'all 300ms ease-in',
    willChange: 'transform, opacity',
    /* overflow: hidden; */
  },

  imgContainer: {
    height: '100%',
    width: '100%',
    backgroundSize: 'cover',
    // backgroundColor: '#b2b2b2',
    [`@media ${DESKTOP}`]: {
      backgroundPosition: 'center 10%',
    },
    [`@media ${MOBILE}`]: {
      backgroundPosition: 'center 15%',
    },
    '& > img': {
      height: '100%',
    },
  },

  darken: {
    opacity: 0.5,
  },

  lighten: {
    opacity: 1,
  },

  active: {
    transform: 'scale(1.025)',
  },

  info: {
    // backgroundColor: '#1F1F1F',
    backgroundColor: 'rgba(31, 31, 31, 0.9)',
    padding: '0.25rem 0.5rem',
    position: 'absolute',

    maxWidth: '85%',
    zIndex: 1,
    opacity: 0,
    transition: 'opacity 300ms ease-in',
    [`@media ${DESKTOP}`]: {
      bottom: '-0.25rem',
      right: '0.8rem',
    },
    [`@media ${MOBILE}`]: {
      bottom: '-0.8rem',
      right: '0.5rem',
    },
  },

  activeInfo: {
    opacity: 1,
    transition: 'opacity 300ms ease-in',
  },

  name: {
    color: '#3BB5A2',
    fontWeight: 'bold',
    [`@media ${DESKTOP}`]: {
      fontSize: '0.625rem',
      lineHeight: 1.5,
    },
    [`@media ${MOBILE}`]: {
      fontSize: '0.75rem',
      lineHeight: '1.1rem',
    },
  },
  title: {
    [`@media ${DESKTOP}`]: {
      fontSize: '0.675rem',
      lineHeight: 1.5,
    },
    [`@media ${MOBILE}`]: {
      fontSize: '0.75rem',
      lineHeight: '1.1rem',
    },
  },
  '@global': {
    '.react-grid-layout': {
      [`@media ${DESKTOP}`]: {
        /* margin-bottom: 10rem; */
        width: '90%',
      },
      [`@media ${MOBILE}`]: {
        width: '100%',
      },
    },
  },
})

import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
// import 'core-js/es/map'
// import 'core-js/es/set'
import { createBrowserHistory } from 'history'
import { configure } from 'mobx'
import { syncHistoryWithStore } from 'mobx-react-router'
import React from 'react'
import ReactDOM from 'react-dom'
import { Route, Router } from 'react-router-dom'
import App from './App'
import { routerStore } from './stores/routerStore'
import './fonts/fonts.css'
import * as serviceWorker from './serviceWorker'

configure({
  enforceActions: 'always',
})

declare global {
  interface Window {
    __forceSmoothScrollPolyfill__: boolean
  }
}

const browserHistory = createBrowserHistory()
const history = syncHistoryWithStore(browserHistory, routerStore)

async function startApp() {
  ReactDOM.render(
    <Router history={history}>
      <Route component={App} props />
    </Router>,
    document.getElementById('root')
  )
}

startApp().catch((err) => console.error(err))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()

import * as React from 'react'

import classnames from 'clsx'
import { WidthProvider, Responsive } from 'react-grid-layout'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import { observer } from 'mobx-react'
// import { computed, observable, action, runInAction } from 'mobx'
import { TeamData, IMemberData } from '../../data/team'
// import bind from 'bind-decorator'
import { generateEmptyIndices, shuffle } from '../../utils/ArrayUtils'
import { generateMobileLayout } from '../../utils/GridUtils'
import { WithStyles, withStyles } from '@material-ui/styles'
import { TeamGridStyles } from './teamGrid.style'
import { breakpoints } from '../../stores/breakpointsStore'
import { windowStore } from '../../stores/windowStore'
import hiringImage from '../../resources/img/Illu_Hiring.svg'
// import logoImage from '../../resources/icons/Codelane_logo_schwarz.svg'
// import LazyImage from '../lazyImage/lazyImage'
// function useStores() {
//   return React.useContext(MobXProviderContext)
// }

const teamData = TeamData
const ResponsiveGridLayout = WidthProvider(Responsive)

const images: { [key: string]: { [key: string]: string } } = {
  sh: {
    src: require('./img/simon.webp'),
    srcFallback: require('./img/simon.jpg'),
  },
  ml: {
    src: require('./img/manuel.webp'),
    srcFallback: require('./img/manuel.jpg'),
  },
  jh: {
    src: require('./img/julie.webp'),
    srcFallback: require('./img/julie.jpg'),
  },
  // gr: require('./img/gabriel.jpg'),
  is: {
    src: require('./img/ilya.webp'),
    srcFallback: require('./img/ilya.jpg'),
  },
  nr: {
    src: require('./img/nico.webp'),
    srcFallback: require('./img/nico.jpg'),
  },
  cf: {
    src: require('./img/carolina.webp'),
    srcFallback: require('./img/carolina.jpg'),
  },
  et: {
    src: require('./img/evgeniya.webp'),
    srcFallback: require('./img/evgeniya.jpg'),
  },
  pe: {
    src: require('./img/pedro.webp'),
    srcFallback: require('./img/pedro.jpg'),
  },
  eas: {
    src: require('./img/erica.webp'),
    srcFallback: require('./img/erica.jpg'),
  },
  ag: {
    src: require('./img/andre.webp'),
    srcFallback: require('./img/andre.jpg'),
  },
}

const margin: [number, number] = [20, 20]
const padding: [number, number] = [30, 0]
const rowsLand = 3
const colsLand = 6
const layoutLand = [
  { i: '0', x: 0, y: 0, w: 2, h: 2, static: true },
  { i: '1', x: 2, y: 0, w: 1, h: 1, static: true },
  { i: '2', x: 3, y: 0, w: 1, h: 1, static: true },
  { i: '3', x: 4, y: 0, w: 1, h: 1, static: true },
  { i: '4', x: 5, y: 0, w: 1, h: 1, static: true },

  { i: '5', x: 2, y: 1, w: 1, h: 1, static: true },
  { i: '6', x: 3, y: 1, w: 1, h: 1, static: true },

  { i: '7', x: 0, y: 2, w: 1, h: 1, static: true },
  { i: '8', x: 1, y: 2, w: 1, h: 1, static: true },
  { i: '9', x: 2, y: 2, w: 1, h: 1, static: true },
  { i: '10', x: 3, y: 2, w: 1, h: 1, static: true },
  { i: '11', x: 4, y: 1, w: 2, h: 2, static: true },
]

// 6 x 3
// const layoutPort = [
//   { i: '0', x: 0, y: 0, w: 2, h: 2, static: true },
//   { i: '1', x: 2, y: 0, w: 1, h: 1, static: true },
//   { i: '2', x: 2, y: 1, w: 1, h: 1, static: true },
//   { i: '3', x: 0, y: 2, w: 1, h: 1, static: true },
//   { i: '4', x: 1, y: 2, w: 1, h: 1, static: true },

//   { i: '5', x: 2, y: 2, w: 1, h: 1, static: true },
//   { i: '6', x: 0, y: 3, w: 1, h: 1, static: true },

//   { i: '7', x: 1, y: 3, w: 1, h: 1, static: true },
//   { i: '8', x: 2, y: 3, w: 1, h: 1, static: true },
//   { i: '9', x: 0, y: 4, w: 1, h: 1, static: true },
//   { i: '10', x: 0, y: 5, w: 1, h: 1, static: true },
//   { i: '11', x: 1, y: 4, w: 2, h: 2, static: true }
// ]

// 4 x 5
const rowsPort = 5
const colsPort = 4
const layoutPort = [
  { i: '0', x: 0, y: 0, w: 2, h: 2, static: true },
  { i: '1', x: 2, y: 0, w: 1, h: 1, static: true },
  { i: '2', x: 3, y: 0, w: 1, h: 1, static: true },
  { i: '3', x: 2, y: 1, w: 1, h: 1, static: true },
  { i: '4', x: 3, y: 1, w: 1, h: 1, static: true },

  { i: '5', x: 0, y: 2, w: 1, h: 1, static: true },
  { i: '6', x: 1, y: 2, w: 1, h: 1, static: true },

  { i: '7', x: 2, y: 2, w: 1, h: 1, static: true },
  { i: '8', x: 3, y: 2, w: 1, h: 1, static: true },
  { i: '9', x: 0, y: 3, w: 1, h: 1, static: true },
  { i: '10', x: 1, y: 3, w: 1, h: 1, static: true },
  { i: '11', x: 0, y: 4, w: 1, h: 1, static: true },
  { i: '12', x: 1, y: 4, w: 1, h: 1, static: true },
  { i: '13', x: 2, y: 3, w: 2, h: 2, static: true },
]

interface IGridItemProps {
  data?: IMemberData
  darken?: boolean
  active?: boolean
  onMouseEnter?(): void
  onMouseLeave?(): void
}

interface IGridProps {
  data: IMemberData[]
  // emptyTiles: number[]
}

type StyleProps = WithStyles<typeof TeamGridStyles>

@observer
class GridItemCls extends React.Component<IGridItemProps & StyleProps> {
  // @bind
  // handleClick (e: any) {
  //   e.preventDefault()
  //   this.props.onMouseEnter()
  // }

  render() {
    const { classes } = this.props
    let animatedInfo: JSX.Element | null = null
    if (this.props.data) {
      animatedInfo = (
        <div
          className={classnames(
            classes.info,
            this.props.active ? classes.activeInfo : null
          )}
        >
          <div className={classes.name}>{this.props.data.name}</div>
          <div className={classes.title}>{this.props.data.title}</div>
        </div>
      )
    }

    return (
      <div
        className={classnames(
          classes.gridItem,
          this.props.darken ? classes.darken : classes.lighten,
          this.props.active && this.props.data ? classes.active : null
        )}
        onMouseEnter={this.props.onMouseEnter}
        onClick={this.props.onMouseEnter}
        onMouseLeave={this.props.onMouseLeave}
      >
        <div
          className={classes.imgContainer}
          style={
            this.props.data
              ? {
                  backgroundImage: `url(${
                    images[this.props.data.id].src
                  }), url(${images[this.props.data.id].srcFallback})`,
                }
              : {
                  backgroundImage: `url(${hiringImage})`,
                  backgroundSize: '50% auto',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                }
          }
        >
          {/* <LazyImage src={this.props.data?.id ? images[this.props.data.id] : hiringImage}/> */}
        </div>
        {animatedInfo}
      </div>
    )
  }
}

const TeamGrid = (props: IGridProps & StyleProps) => {
  const ref = React.useRef<HTMLDivElement>(null)
  const [hoveredIdx, setHoveredIdx] = React.useState<number | null>(null)
  const [isPortrait, setIsPortrait] = React.useState<boolean>(
    windowStore.height > windowStore.width
  )

  React.useEffect(() => {
    const resizeListener = () => {
      setIsPortrait(windowStore.height > windowStore.width)
    }
    window.addEventListener('resize', resizeListener)
    return () => {
      window.removeEventListener('resize', resizeListener)
    }
  })

  const [rowHeight, setRowHeight] = React.useState<number>(0)
  React.useEffect(() => {
    let rh = 0
    const rectHeight = ref?.current
      ? ref.current.getBoundingClientRect().height - windowStore.height * 0.07
      : 0
    if (Boolean(breakpoints.desktop)) {
      rh = isPortrait
        ? (rectHeight - rowsPort * margin[0]) / rowsPort
        : (rectHeight - rowsLand * margin[0]) / rowsLand
    } else {
      rh = Math.max(windowStore.height * 0.2)
    }
    setRowHeight(rh)
  }, [isPortrait])

  const cols = Boolean(breakpoints.desktop)
    ? isPortrait
      ? colsPort
      : colsLand
    : 2

  const [layout, setLayout] = React.useState<any>(null)
  React.useEffect(() => {
    if (Boolean(breakpoints.desktop)) {
      shuffle(props.data)
      if (isPortrait) {
        setLayout(layoutPort)
      } else {
        setLayout(layoutLand)
      }
    } else {
      setLayout(generateMobileLayout(props.data.length))
    }
  }, [isPortrait, props.data, props.data.length])

  const [emptyTiles, setEmptyTiles] = React.useState<number[]>([])
  React.useEffect(() => {
    if (layout != null) {
      const tiles: number[] = generateEmptyIndices(
        1,
        layout.length - 2,
        layout.length - teamData.members.length
      )
      setEmptyTiles(tiles)
    }
  }, [layout])
  const children = React.useMemo(() => {
    const gridItems: JSX.Element[] = []
    let j = 0

    if (layout != null) {
      for (let i = 0; i < layout.length; i++) {
        let data = undefined
        if (
          emptyTiles.indexOf(i) === -1 ||
          i === 0 ||
          i === layout.length - 1
        ) {
          data = props.data[j]
        } else {
          j -= 1
        }
        j += 1
        gridItems.push(
          <div key={i}>
            <GridItem
              data={data}
              darken={hoveredIdx !== null && i !== hoveredIdx}
              active={i === hoveredIdx}
              onMouseEnter={() => setHoveredIdx(i)}
              onMouseLeave={() => setHoveredIdx(null)}
            />
          </div>
        )
      }
    }
    return gridItems
  }, [emptyTiles, hoveredIdx, layout, props.data])
  return (
    <div className={props.classes.gridContainer} ref={ref}>
      {layout != null && (
        <ResponsiveGridLayout
          className="layout"
          layouts={{
            lg: layout,
            md: layout,
            sm: layout,
            xs: layout,
            xxs: layout,
          }}
          cols={{ lg: cols, md: cols, sm: cols, xs: cols, xxs: cols }}
          rowHeight={rowHeight}
          autoSize={true}
          margin={margin}
          containerPadding={Boolean(breakpoints.desktop) ? padding : [10, 20]}
          children={children}
        />
      )}
    </div>
  )
}
export const GridItem = withStyles(TeamGridStyles, {
  name: 'GridItem',
})(GridItemCls)
export default withStyles(TeamGridStyles, { name: 'TeamGrid' })(TeamGrid)

import { MOBILE, DESKTOP } from '../utils/styles'
import { createStyles } from '@material-ui/styles'

export const TechnologyLogosStyles = () =>
  createStyles({
    container: {
      [`@media ${DESKTOP}`]: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        // justifyContent: 'center',
        // marginBottom: ,
        position: 'relative',
        maxHeight: '3rem',
        overflow: 'hidden',
        // position: 'absolute',
        // bottom: '2rem',
        // left: 0,
        [`@media ${MOBILE}`]: {
          position: 'absolute',
          bottom: '2rem',
        },
        '& $bg1, $bg2': {
          height: '100%',
          // width: '100.1%',
          width: 'auto',
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
        },
      },
      [`@media ${MOBILE}`]: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        // position: 'absolute',
        // top: 0,
        width: '100%',
        /* -webkit-overflow-scrolling: touch;  */
      },
    },
    logosRow: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      [`@media ${DESKTOP}`]: {
        height: '6rem',
      },
      [`@media ${MOBILE}`]: {
        height: '4rem',
        maxHeight: '10vh',
      },
    },
    logo: {
      // maxHeight: '2.5rem',
      // maxWidth: '6rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      [`@media ${DESKTOP}`]: {
        margin: '0 1rem',
        '& img': {
          height: '100%',
          width: 'auto',
          maxHeight: '2rem',
          minHeight: '2rem',
          maxWidth: '5rem',
        },
      },
      [`@media ${MOBILE}`]: {
        maxHeight: '2.5rem',
        maxWidth: '3.75rem',
        '& img': {
          width: '100%',
          height: 'auto',
          maxHeight: '2.5rem',
        },
      },
    },
    bg1: {},
    animatedBg1: {
      animation: '$move1 linear infinite reverse',
    },
    animatedBg2: {
      animation: '$move2 linear infinite reverse',
    },
    animatedBg3: {
      animation: '$move3 linear infinite reverse',
    },
    animatedBg4: {
      animation: '$move4 linear infinite reverse',
    },
    animatedBg5: {
      animation: '$move5 linear infinite reverse',
    },
    animatedBg6: {
      animation: '$move6 linear infinite reverse',
    },
    animatedBg7: {
      animation: '$move6 linear infinite reverse',
    },
    animatedBg8: {
      animation: '$move6 linear infinite reverse',
    },
    animatedBg9: {
      animation: '$move6 linear infinite reverse',
    },

    bg2: {
      left: 0,
      /* right: 0; */
    },
    animatedBg: {
      willChange: 'transform',
    },
    '@keyframes move1': {
      from: { transform: 'translateX(-100%)' },
      to: { transform: 'translateX(0)' },
    },

    '@keyframes move2': {
      from: { transform: 'translateX(0)' },
      to: { transform: 'translateX(100%)' },
    },
    '@keyframes move3': {
      from: { transform: 'translateX(100%)' },
      to: { transform: 'translateX(200%)' },
    },
    '@keyframes move4': {
      from: { transform: 'translateX(200%)' },
      to: { transform: 'translateX(300%)' },
    },
    '@keyframes move5': {
      from: { transform: 'translateX(300%)' },
      to: { transform: 'translateX(400%)' },
    },
    '@keyframes move6': {
      from: { transform: 'translateX(400%)' },
      to: { transform: 'translateX(500%)' },
    },
  })

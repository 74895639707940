import React from 'react'
import clsx from 'clsx'
import { observer } from 'mobx-react'
import { WithStyles, withStyles } from '@material-ui/styles'
import { ArrowDownStyles } from './arrowDown.styles'
import arrow from '../resources/img/Pfeile.svg'
import { windowStore } from '../stores/windowStore'

interface ArrowDownProps extends WithStyles<typeof ArrowDownStyles> {
  className?: string
  arrowPos?: string
}

@observer
class ArrowDownCls extends React.Component<ArrowDownProps> {
  render() {
    const { classes } = this.props
    return (
      <div
        className={clsx(classes.container, this.props.className)}
        style={{
          bottom: this.props.arrowPos != null ? this.props.arrowPos : '0',
          height: 0.06 * windowStore.height,
          width: windowStore.width,
        }}
      >
        <img
          className={clsx(classes.arrow, classes.animatedArrow)}
          src={arrow}
          alt="arrow-down"
        />
      </div>
    )
  }
}

export default withStyles(ArrowDownStyles, {
  name: 'ArrowDown',
  withTheme: true,
})(ArrowDownCls)

import { MOBILE, DESKTOP } from '../utils/styles'
import { createStyles } from '@material-ui/styles'

export const FooterStyles = createStyles({
  footerBox: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    paddingTop: '12.5%',
    fontSize: '0.9rem',
    zIndex: 100,
    [`@media ${DESKTOP}`]: {
      flexDirection: 'row',
    },
    [`@media ${MOBILE}`]: {
      flexDirection: 'column',
      /* margin-top: 50vh; */
    },
  },
  column: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    [`@media ${MOBILE}`]: {
      width: '100%',
    },
  },

  logo: {
    width: '9rem',
    [`@media ${DESKTOP}`]: {
      '@media (orientation: portrait)': {
        width: '7rem',
      },
    },
  },
  address: {
    textAlign: 'center',
    '& > p': {
      marginTop: 4,
      marginBottom: 4,
    },
    '& a': {
      fontWeight: 'bold',
    },
  },
})

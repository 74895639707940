import { createStyles } from '@material-ui/styles'

export const ProjectTagStyles = createStyles({
  projectTag: {
    cursor: 'pointer',

    alignSelf: 'center',

    padding: '0.2rem 0.6rem',
    margin: '0.25rem',
    color: '#fff',

    textTransform: 'uppercase',

    border: 'solid white 1px',
    borderRadius: 50,
    textDecoration: 'none',

    fontSize: '0.5rem',
    lineHeight: '1rem',
    fontWeight: 700,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

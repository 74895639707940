import * as React from 'react'
// import clsx from 'clsx'
// import Carousel from '../../common/carousel'
import { observer } from 'mobx-react'
import { observable, action, reaction, runInAction } from 'mobx'
import SwipeDots from '../../common/swipeDots'
import { WithStyles, withStyles } from '@material-ui/styles'
import { CompetencesStyles } from './competences.style'
import { windowStore } from '../../stores/windowStore'
import { breakpoints } from '../../stores/breakpointsStore'
// import bgDesktop from '../../resources/img/competences-bg.svg'
// import bgMobile from '../../resources/img/competences-bg-mobile.svg'
import bind from 'bind-decorator'
import Section from '../../common/section'
import { Link } from 'react-router-dom'
import SwipeableViews from 'react-swipeable-views'
import { SlideRendererParams } from 'react-swipeable-views-utils'
import LazyImage from '../../common/lazyImage/lazyImage'
import TechnologyLogos from '../../common/technologyLogos'
// import iconClose from '../../resources/icons/icon-close.svg'

// const SwipeableViewsVirtual = virtualize(SwipeableViews)

const contentData = [
  {
    type: 'app+web',
    image: require('../../resources/img/Illu_App.svg'),
    title: 'Mobile and web apps',
    text:
      'We create interactive and engaging digital experiences - from business case and concept to design to implementation and user testing.',
  },
  {
    type: 'video+3d',
    image: require('../../resources/img/Illu_Video.svg'),
    title: 'Video analytics / 3D graphics',
    text:
      'Get insights into your videos with computer vision and stay ahead with your brand-specific 3D or augmented reality application.',
  },
  {
    type: 'ml',
    image: require('../../resources/img/Illu_Simulations.svg'),
    title: 'Machine learning',
    text:
      'We build custom deep-learning architectures for video/data analysis and simulations of human behavior for business intelligence.',
  },
]

interface ICompProps {
  competence: any
}

type StyleProps = WithStyles<typeof CompetencesStyles>
interface ICompetencesProps extends StyleProps {
  id?: string
  active?: boolean
  fullpageApi?: any
}

@observer
class CompetenceColumnCls extends React.Component<ICompProps & StyleProps> {
  render() {
    const { classes, competence } = this.props
    return (
      <div
        className={classes.column}
        style={{
          padding: `0 ${
            (windowStore.width > 1366 ? 0.03 : 0.02) * windowStore.width
          }px ${
            Boolean(breakpoints.desktop) && windowStore.isPortrait ? 20 : 0
          }%`,
        }}
      >
        <div
          className={classes.columnImage}
          style={{
            height:
              (windowStore.width > 1366 ? 0.25 : 0.2) * windowStore.height,
          }}
        >
          <LazyImage
            src={competence.image}
            alt={competence.title}
            style={{
              maxHeight: 0.25 * windowStore.height,
              maxWidth: '90%',
              objectFit: 'contain',
            }}
          />
          {/* <img src={competence.image} alt="competences" /> */}
        </div>
        <div className={classes.columnTextContent}>
          <div className={classes.columnTitle}>{competence.title}</div>
          <div className={classes.columnText}>{competence.text}</div>
          <Link to={`/projects#${competence.type as string}`}>
            <div
              className={classes.learnMoreButton}
              style={
                Boolean(breakpoints.desktop)
                  ? { maxWidth: 0.15 * windowStore.width }
                  : undefined
              }
            >
              Check projects
            </div>
          </Link>
        </div>
      </div>
    )
  }
}

@observer
class Competences extends React.Component<ICompetencesProps> {
  logosRect = React.createRef<HTMLDivElement>()
  @observable scrollDist = 0
  @observable activeSlideIdx = 0
  @observable techOpen: boolean = false
  @observable nOfLogosRects: number = 0

  componentDidMount() {
    reaction(
      () => windowStore.width,
      (ww: number) => {
        runInAction(() => {
          if (this.logosRect && this.logosRect.current) {
            const rect = this.logosRect.current.getBoundingClientRect()
            const { width } = rect
            const n = width > ww ? 1 : Math.floor(ww / width) + 1
            this.nOfLogosRects = n
          }
        })
      }
    )
  }

  componentDidUpdate() {
    const ww = windowStore.width
    if (this.nOfLogosRects === 0 && this.logosRect && this.logosRect.current) {
      const rect = this.logosRect.current.getBoundingClientRect()
      const { width } = rect
      const n = width > ww ? 1 : Math.floor(ww / width) + 1
      runInAction(() => {
        this.nOfLogosRects = n
      })
    }
  }

  static slideScrollHeight() {
    return Boolean(breakpoints.desktop) ? 180 : 200
  }

  readonly animationSettings = {
    scaleSpeed: 32,
    speedX: (windowStore.width * 0.8) / 100,
    speedY: windowStore.height / 2,
  }

  slideRenderer(param: SlideRendererParams): JSX.Element {
    if (param.index >= 0 && param.index < contentData.length) {
      const column = contentData[param.index]
      return <CompetenceColumn competence={column} key={param.index} />
    }

    return <div></div>
  }

  @bind
  @action
  setActiveSlideIdx(idx: number) {
    this.activeSlideIdx = idx
  }

  @bind
  @action
  toggleTechButton() {
    this.techOpen = !this.techOpen
    if (this.props.fullpageApi != null) {
      if (this.techOpen) {
        this.props.fullpageApi.setAllowScrolling(false)
      } else {
        this.props.fullpageApi.setAllowScrolling(true)
      }
    }
  }

  render() {
    const { classes, id, active } = this.props
    const scroll = 0
    const columns = []
    for (const contentEntry of contentData) {
      columns.push(
        <CompetenceColumn competence={contentEntry} key={contentEntry.title} />
      )
    }

    let content: JSX.Element | null = null
    if (Boolean(breakpoints.phone)) {
      const columns: JSX.Element[] = []
      for (let i = 0; i < contentData.length; i++) {
        columns.push(<CompetenceColumn competence={contentData[i]} key={i} />)
      }
      columns.push(<TechnologyLogos key="logos" />)
      content = (
        <SwipeableViews
          className={classes.columnsBox}
          resistance
          style={{
            transform: `translate3d(0px, ${
              -scroll * this.animationSettings.speedY
            }px, 0px)`,
            height: windowStore.height,
            width: windowStore.width,
            marginTop: 0.025 * windowStore.height,
          }}
          /*
            slideRenderer={(i) => this.slideRenderer(i)}
            slideCount={contentData.length}
            */
          onSwitching={(i) => {
            this.setActiveSlideIdx(i)
          }}
          /* onTransitionEnd={() => (commonStore.fancyScroll = true)} */
        >
          {columns}
        </SwipeableViews>
      )
    } else {
      content = (
        <div
          className={classes.columnsBox}
          style={{
            width: 0.9 * windowStore.width,
            transform: `translate3d(0, ${
              -scroll * this.animationSettings.speedY
            }px, 0)`,
            padding: `0 ${0.05 * windowStore.width}px`,
          }}
        >
          {columns}
        </div>
      )
    }
    return (
      <Section
        animateTitle
        title={[
          'We are technology builders',
          'We are experience builders',
          'We are company builders',
        ]}
        id={id}
      >
        {/* {background} */}
        {content}
        {/* {technologies} */}
        {/* {techBg}
        {techButton} */}
        {Boolean(breakpoints.desktop) && <TechnologyLogos animate={active} />}
        {Boolean(breakpoints.phone) && (
          <SwipeDots
            count={contentData.length + 1}
            activeSlideIdx={this.activeSlideIdx}
            scroll={scroll}
            distanceToArrows="-50vh"
          />
        )}
      </Section>
    )
  }
}

export const CompetenceColumn = withStyles(CompetencesStyles, {
  name: 'CompetenceColumn',
})(CompetenceColumnCls)
export default withStyles(CompetencesStyles, { name: 'Competences' })(
  Competences
)

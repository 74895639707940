import { createStyles } from '@material-ui/styles'
import arrowNext from './resources/icons/arrow-next.svg'
import arrowBack from './resources/icons/arrow-back.svg'
import { DESKTOP, MOBILE } from './utils/styles'

export const AppStyles = createStyles({
  '@global': {
    html: {
      overflow: 'hidden',
      position: 'relative',
      height: '100%',
      width: '100%',
      fontSize: 'calc(16px + 9*(100vw - 420px)/1500);',
      '@media (max-width: 420px)': {
        fontSize: '16px',
      },
      '@media (min-width: 1920px)': {
        fontSize: '25px',
      },
    },
    button: {
      '&:focus': {
        outline: 0,
      },
    },
    body: {
      backgroundColor: '#000',
      'overscroll-behavior': 'none',
      height: '100%',
      width: '100%',
      overflow: 'hidden',
      margin: 0,
      padding: 0,
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#fff',
      '-webkit-transform': 'translateZ(0)',
      '-webkit-backface-visibility': 'hidden',
      fontFamily: `WorkSans, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif`,
      '-webkitFontSmoothing': 'antialiased',
      '-mozOsxFontSmoothing': 'grayscale',
    },
    'a, a:active, a:visited, a:hover': {
      textDecoration: 'none',
      color: '#fff',
    },
    h1: {
      fontSize: '2.8rem',
    },
    '#root': {
      height: '100%',
      width: '100%',
      // display: 'flex',
      // flexDirection: 'column',
      // justifyContent: 'flex-start',
      // alignItems: 'center'
    },
    '.react-swipeable-view-container': {
      height: '100%',
    },
    '*': {
      margin: 0,
      padding: 0,
      boxSizing: 'border-box',
    },
    '*:not(input)': {
      '-webkit-touch-callout': 'none' /* iOS Safari */,
      '-webkit-user-select': 'none' /* Safari */,
      '-khtml-user-select': 'none' /* Konqueror HTML */,
      '-moz-user-select': 'none' /* Firefox */,
      '-ms-user-select': 'none' /* Internet Explorer/Edge */,
      'user-select': 'none',
    },
    [`@media ${DESKTOP}`]: {
      '.fp-controlArrow': {
        '&.fp-prev': {
          backgroundImage: `url(${arrowBack})`,
          width: '2rem',
          height: '2rem',
          left: '2rem',
          borderStyle: 'none',
        },
        '&.fp-next': {
          backgroundImage: `url(${arrowNext})`,
          width: '2rem',
          height: '2rem',
          right: '2rem',
          borderStyle: 'none',
        },
      },
    },
    [`@media ${MOBILE}`]: {
      '.fp-controlArrow': {
        '&.fp-prev': {
          display: 'none',
        },
        '&.fp-next': {
          display: 'none',
        },
      },
    },
  },
})

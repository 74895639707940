import { MOBILE, DESKTOP } from '../../utils/styles'
import { createStyles } from '@material-ui/styles'

export const ClientsStyles = createStyles({
  container: {
    [`@media ${MOBILE}`]: {},
    [`@media ${DESKTOP}`]: {},
  },
  text: {
    [`@media ${MOBILE}`]: {
      marginTop: '2rem',
    },
  },
  logosContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // flex: 1,
    paddingTop: '4rem',
    [`@media ${DESKTOP}`]: {
      width: '70%',
      maxWidth: 1600,
    },
    [`@media ${MOBILE}`]: {
      width: '100%',
      maxWidth: 540,
      padding: '5% 1.5rem 0',
    },
  },
  logosRow: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    [`@media ${DESKTOP}`]: {
      height: '6rem',
      '@media (orientation:portrait)': {
        maxHeight: '85px !important',
      },
    },
    [`@media ${MOBILE}`]: {
      height: '4rem',
      maxHeight: '10vh',
    },
  },
  lastRow: {
    [`@media ${DESKTOP}`]: {
      width: '60%',
    },
  },
  logo: {
    // maxHeight: '2.5rem',
    // maxWidth: '6rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    width: '4.5rem',
    height: '2rem',
    '& img': {
      // filter: 'grayscale(1) brightness(1.5)',
      width: '100%',
      height: 'auto',
    },
    [`@media ${DESKTOP}`]: {
      '& img': {
        maxHeight: '4.5rem',
        maxWidth: '4.5rem',
        '@media (orientation: portrait)': {
          maxWidth: '4rem',
        },
      },
    },
    [`@media ${MOBILE}`]: {
      maxHeight: '3.5rem',
      maxWidth: '3.5rem',
    },
  },
  callToActionBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [`@media ${DESKTOP}`]: {
      marginTop: '3rem',
      '@media (orientation:portrait)': {
        flexDirection: 'column',
        marginTop: '3rem',
      },
    },
    [`@media ${MOBILE}`]: {
      flexDirection: 'column',
      marginTop: '1rem',
    },
  },
  callToActionText: {
    fontSize: '1.2rem',
    marginRight: '4rem',
    '@media (orientation:portrait)': {
      marginRight: 0,
    },
  },
  calltoActionButton: {
    fontWeight: 'bold',
    fontSize: '0.725rem',
    lineHeight: 1.25,
    textAlign: 'center',
    boxShadow: 'inset 0 0 20px 20px transparent',
    transition: 'box-shadow 200ms ease',
    // animation: '1.75s ease-in-out 0s infinite normal both running $heartbeat',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [`@media ${DESKTOP}`]: {
      width: '10rem',
      borderRadius: 5,
      backgroundColor: 'rgba(58,181,161,1)',
      padding: '0.5rem 0.5rem 0.5rem 0.5rem',
      '@media (orientation:portrait)': {
        marginTop: '1.5rem',
      },
    },
    [`@media ${MOBILE}`]: {
      borderRadius: 5,
      backgroundColor: 'rgba(58,181,161,1)',
      width: '6rem',
      height: '2rem',
      marginTop: '1rem',
    },
    '&:hover': {
      boxShadow: 'inset 0 0 20px 20px rgba(0, 0, 0, 0.1)',
    },
  },
})

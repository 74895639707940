import * as React from 'react'
import classnames from 'clsx'

import { LandingStyles } from './landing.styles'

import logo from '../../resources/icons/Codelane_logo_weiss.svg'
import car from './illustrations/Illu_Auto.svg'
// const moto = require('./illustrations/Illu_Roller.svg')
// const velo = require('./illustrations/Illu_Rad.svg')
import markerA from './illustrations/Illu_A.svg'
import markerB from './illustrations/Illu_B.svg'
import sun from './illustrations/Illu_Sonne.svg'
import cloud from './illustrations/Illu_Regenwolke.svg'

import { observer } from 'mobx-react'
import { observable } from 'mobx'
import Section from '../../common/section'
// import { windowStore } from '../../stores/windowStore'
import { withStyles, WithStyles } from '@material-ui/styles'
import { breakpoints } from '../../stores/breakpointsStore'
import Mountains from '../../common/mountains'
import { windowStore } from '../../stores/windowStore'
// import StickyContainer from '../../StickyContainer'

const text =
  "Whether you're a small business or large corporation, we create amazing digital experiences and cutting-edge technologies to safely navigate your business into the digital future. Are you ready?"

type StyleProps = WithStyles<typeof LandingStyles>

interface ILandingProps extends StyleProps {
  id?: string
  active?: boolean
}

@observer
class Landing extends React.Component<ILandingProps> {
  @observable move = true

  static slideScrollHeight() {
    return Boolean(breakpoints.desktop) ? 160 : 200
  }

  render() {
    const { classes, id } = this.props

    const digitalHumanButton = (
      <a
        id="dh-landing"
        className={classes.digitalHumanButton}
        href="https://humans.codelane.ch"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span>Explore our</span>
        <br />
        <span>Digital Human Platform</span>
      </a>
    )
    return (
      <Section id={id}>
        <div
          className={classes.landingContainer}
          style={{ minHeight: windowStore.height, width: windowStore.width }}
        >
          <Mountains move={this.props.active} />
          <div className={classes.text}>{text}</div>
          {digitalHumanButton}
          <div className={classes.animationBox}>
            <div
              className={classes.horizontal}
              style={{ width: windowStore.width }}
            >
              <div className={classes.left}>
                <img
                  className={classes.cloud}
                  src={cloud}
                  alt="cloud"
                  style={{
                    left:
                      (Boolean(breakpoints.desktop) ? 0.065 : 0) *
                      windowStore.width,
                  }}
                />
                <img
                  className={classes.markerA}
                  src={markerA}
                  alt="markerA"
                  style={{
                    bottom:
                      (Boolean(breakpoints.desktop) ? 0.08 : 0.06) *
                      windowStore.height,
                    left: Boolean(breakpoints.desktop)
                      ? '3rem'
                      : 0.05 * windowStore.width,
                  }}
                />
              </div>
              <div className={classes.center}>
                <img className={classes.logo} src={logo} alt="logo" />
              </div>
              <div className={classes.right}>
                <img className={classes.sun} src={sun} alt="sun" />
                <img
                  className={classes.markerB}
                  src={markerB}
                  alt="markerB"
                  style={{ bottom: 0.06 * windowStore.height }}
                />
              </div>
            </div>

            <div
              className={classes.bottom}
              /* style={{ transform: `translateX(${scroll * this.store.storeCommon.window.width / 3}px)` }} */
            >
              <img
                className={classnames(classes.car, classes.animatedCar)}
                src={car}
                alt="car"
              />
              {/* <img className={classnames(classes.moto)} src={moto} alt='moto'/> */}
              {/* <img className={classnames(classes.velo)} src={velo} alt='velo'/> */}
            </div>
          </div>
        </div>
      </Section>
    )
  }
}

export default withStyles(LandingStyles, { name: 'Landing' })(Landing)

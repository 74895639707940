import React from 'react'
import { observer } from 'mobx-react'
import { WithStyles, withStyles, createStyles } from '@material-ui/styles'
import { windowStore } from '../stores/windowStore'
import { MOBILE, DESKTOP } from '../utils/styles'
import { InView } from 'react-intersection-observer'
import ArrowDown from './arrowDown'
import Typed from 'react-typed'
import 'react-typed/dist/animatedCursor.css'
require('intersection-observer')

export const SectionStyles = createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    // position: 'absolute',
    // top: 0,
  },
  slide: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },

  title: {
    textAlign: 'left',
    width: '100%',
    [`@media ${DESKTOP}`]: {
      padding: '10vh 10vw 4vh 10vw',
      '& > h2': {
        fontSize: '2rem',
        fontWeight: 'normal',
      },
    },
    [`@media ${MOBILE}`]: {
      padding: '3rem 2.5rem 1.5rem 2.5rem',
      '& > h2': {
        fontSize: '1.5rem',
        fontWeight: 'normal',
      },
    },
  },
})

interface SectionProps extends WithStyles<typeof SectionStyles> {
  title?: string | string[]
  id?: string
  animateTitle?: boolean
}

@observer
class SectionCls extends React.Component<SectionProps> {
  render() {
    const { classes, title } = this.props

    let sectionTitle: JSX.Element | null = null
    if (title != null) {
      if (!this.props.animateTitle) {
        sectionTitle = <>{typeof title === 'string' ? title : title[0]}</>
      } else {
        sectionTitle = (
          <Typed
            strings={typeof title === 'string' ? [title] : title}
            typeSpeed={40}
            backSpeed={50}
            loop
          />
        )
      }
    }
    return (
      <div
        className={classes.container}
        style={{
          width: windowStore.width,
          height: windowStore.height,
          minHeight: windowStore.height,
        }}
      >
        <InView
          rootMargin={`${windowStore.height}px 0px`}
          // triggerOnce
        >
          {({ inView, ref }) => {
            return (
              <div ref={ref} className={classes.slide} style={{}}>
                {this.props.title != null && (
                  <div className={classes.title}>
                    <h2>{sectionTitle}</h2>
                  </div>
                )}
                {inView ? this.props.children : null}
                {this.props.id != null && this.props.id !== 'hiring' && (
                  <ArrowDown />
                )}
              </div>
            )
          }}
        </InView>
      </div>
    )
  }
}

export default withStyles(SectionStyles, { name: 'Section', withTheme: true })(
  SectionCls
)

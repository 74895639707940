import React from 'react'
import clsx from 'clsx'
import { observer } from 'mobx-react'
import { WithStyles, withStyles } from '@material-ui/styles'
import { windowStore } from '../stores/windowStore'
import { breakpoints } from '../stores/breakpointsStore'
import { TechnologyLogosStyles } from './technologyLogos.styles'
import { observable, reaction, action } from 'mobx'
import bind from 'bind-decorator'

const technologiesList: { [key: string]: any } = {
  aws: {
    img: require('../resources/icons/technologyLogos/aws.svg'),
    url: 'https://www.baloise.ch/de/privatkunden.html',
  },
  azure: {
    img: require('../resources/icons/technologyLogos/azure.svg'),
    url: 'https://azure.microsoft.com/en-us/',
  },
  cordova: {
    img: require('../resources/icons/technologyLogos/cordova.svg'),
    url: 'https://cordova.apache.org/',
  },
  googleCloud: {
    img: require('../resources/icons/technologyLogos/google-cloud.svg'),
    url: 'https://cloud.google.com/',
  },
  kubernetes: {
    img: require('../resources/icons/technologyLogos/kubernetes.svg'),
    url: 'https://kubernetes.io/',
  },
  pytorch: {
    img: require('../resources/icons/technologyLogos/pytorch.svg'),
    url: 'https://pytorch.org/',
  },
  tensorflow: {
    img: require('../resources/icons/technologyLogos/tensorflow.svg'),
    url: 'https://www.tensorflow.org/',
  },
  unrealEngine: {
    img: require('../resources/icons/technologyLogos/unreal-engine.svg'),
    url: 'https://www.unrealengine.com/en-US/',
  },
  vulkan: {
    img: require('../resources/icons/technologyLogos/vulkan.svg'),
    url: 'https://www.amd.com/en/technologies/vulkan',
  },
  highcharts: {
    img: require('../resources/icons/technologyLogos/highcharts.svg'),
    url: 'https://www.highcharts.com/',
  },
  prismic: {
    img: require('../resources/icons/technologyLogos/prismic.svg'),
    url: 'https://prismic.io/',
  },
  react: {
    img: require('../resources/icons/technologyLogos/react.svg'),
    url: 'https://reactjs.org/',
  },
}

const technologiesOrderDesktop = [
  'azure',
  'googleCloud',
  'kubernetes',
  'aws',
  'pytorch',
  'tensorflow',
  'unrealEngine',
  'vulkan',
  'highcharts',
  'prismic',
  'react',
  'cordova',
]

const technologiesOrderMobile = [
  'aws',
  'react',
  'unrealEngine',
  'azure',
  'googleCloud',
  'kubernetes',
  'pytorch',
  'vulkan',
  'tensorflow',
  'cordova',
  'highcharts',
  'prismic',
]

interface TechnologyLogosProps
  extends WithStyles<typeof TechnologyLogosStyles> {
  animate?: boolean
}

@observer
class TechnologyLogosCls extends React.Component<TechnologyLogosProps> {
  logosRect = React.createRef<HTMLDivElement>()
  @observable nOfLogosRects: number = 0
  @observable loaded = false

  componentDidMount() {
    reaction(
      () => [this.loaded, windowStore.width],
      ([loaded, ww]: Array<boolean | number>) => {
        if (loaded as boolean) {
          this.setLogosRects(ww as number)
        }
      }
    )
  }

  readonly animationSettings = {
    scaleSpeed: 32,
    speedX: (windowStore.width * 0.8) / 100,
    speedY: windowStore.height / 2,
  }

  @bind
  @action
  setLogosRects(ww: number) {
    if (this.logosRect && this.logosRect.current) {
      const rect = this.logosRect.current.getBoundingClientRect()
      const width = rect.width
      const n = width > ww ? 1 : Math.floor(ww / width) + 1
      this.nOfLogosRects = n
    }
  }

  @bind
  @action
  onLoad(i: number) {
    if (i === 0) {
      this.loaded = true
    }
  }

  render() {
    const { classes, animate } = this.props
    let technologies: JSX.Element | null = null
    const technologiesOrder =
      Boolean(breakpoints.desktop) && windowStore.isLandscape
        ? technologiesOrderDesktop
        : technologiesOrderMobile
    if (Boolean(breakpoints.desktop)) {
      const animationDuration = `${
        windowStore.width / this.animationSettings.speedX
      }s`
      const logos = []
      for (let i = 0; i < technologiesOrder.length; i++) {
        const logo = technologiesList[technologiesOrder[i]]
        const id = technologiesOrder[i]
        logos.push(
          <div className={classes.logo} key={i}>
            <a href={logo.url} target="_blank" rel="noreferrer noopener">
              <img src={logo.img} alt={id} onLoad={() => this.onLoad(i)} />
            </a>
          </div>
        )
      }
      const repeatableRects = []
      if (this.nOfLogosRects != null) {
        for (let j = 0; j < this.nOfLogosRects; j++) {
          const animatedClass = `animatedBg${j + 2}` as
            | 'animatedBg2'
            | 'animatedBg3'
            | 'animatedBg4'
            | 'animatedBg5'
            | 'animatedBg6'
            | 'animatedBg7'
            | 'animatedBg8'
            | 'animatedBg9'
          repeatableRects.push(
            <div
              key={j}
              className={clsx(classes['bg1'], classes[animatedClass])}
              style={{
                animationDuration,
                animationPlayState: animate ? 'running' : 'pause',
              }}
            >
              {logos}
            </div>
          )
        }
      }
      technologies = (
        <div
          className={classes.container}
          style={{
            height:
              (Boolean(breakpoints.desktop) ? 0.15 : 0.1) * windowStore.height,
            marginBottom: 0.1 * windowStore.height,
          }}
        >
          <div
            ref={this.logosRect}
            className={clsx(classes.bg1, classes.animatedBg1)}
            style={{
              animationDuration,
              animationPlayState: animate ? 'running' : 'pause',
            }}
          >
            {logos}
          </div>
          {repeatableRects}
        </div>
      )
    } else {
      const rows = 4
      const cols = 3
      const logoRows = []
      let logoCols = []
      for (let i = 0; i < rows; i++) {
        for (let j = 0; j < cols; j++) {
          const idx = cols * i + j
          const tech = technologiesOrder[idx]
          // console.log(idx, client)
          if (tech != null) {
            logoCols.push(
              <div key={tech} className={classes.logo}>
                <a href={technologiesList[tech].url}>
                  <img src={technologiesList[tech].img} alt={tech} />
                </a>
              </div>
            )
          }
        }
        logoRows.push(
          <div key={i} className={classes.logosRow}>
            {logoCols}
          </div>
        )
        logoCols = []
      }
      technologies = <div className={classes.container}>{logoRows}</div>
    }

    return technologies
  }
}

export default withStyles(TechnologyLogosStyles, {
  name: 'TechnologyLogos',
})(TechnologyLogosCls)

import React from 'react'
import clsx from 'clsx'
import { observer } from 'mobx-react'
import { WithStyles, withStyles } from '@material-ui/styles'
import { ClientsStyles } from './clients.styles'
import Section from '../../common/section'
import { breakpoints } from '../../stores/breakpointsStore'
import { computed } from 'mobx'
import { windowStore } from '../../stores/windowStore'
import LazyImage from '../../common/lazyImage/lazyImage'

const text = 'Join the companies that trust us!'
const email = 'hello@codelane.ch'
const subject = 'I want to build something great with you!'
const callToActionText = 'Want your logo on this page?'
const buttonText = 'Contact us'

const clientsList: { [key: string]: any } = {
  fourquant: {
    img: require('./logosGray/4quant.webp'),
    imgFallback: require('./logosGray/4quant.png'),
    url: 'https://4quant.com/',
  },
  baloise: {
    img: require('./logosGray/baloise.svg'),
    url: 'https://www.baloise.ch/de/privatkunden.html',
  },
  bcg: {
    img: require('./logosGray/bcg.webp'),
    imgFallback: require('./logosGray/bcg.png'),
    url: 'https://www.bcg.com/en-ch/',
  },
  css: {
    img: require('./logosGray/css.svg'),
    url: 'https://www.css.ch/de/privatkunden.html',
  },
  docmine: {
    img: require('./logosGray/docmine.webp'),
    imgFallback: require('./logosGray/docmine.png'),
    url: 'http://www.docmine.com/',
  },
  kantonsrat: {
    img: require('./logosGray/kantonsrat.webp'),
    imgFallback: require('./logosGray/kantonsrat.png'),
    url: 'https://www.kantonsrat.zh.ch/',
  },
  guuru: {
    img: require('./logosGray/guuru.webp'),
    imgFallback: require('./logosGray/guuru.png'),
    url: 'https://www.guuru.com/en/',
  },
  novartis: {
    img: require('./logosGray/novartis.svg'),
    url: 'https://www.novartis.com/',
  },
  nxgen: {
    img: require('./logosGray/nxgen.webp'),
    imgFallback: require('./logosGray/nxgen.png'),
    url: 'https://www.nxgen.io/',
  },
  oebb: {
    img: require('./logosGray/oebb.webp'),
    imgFallback: require('./logosGray/oebb.png'),
    url: 'https://www.oebb.at/en/',
  },
  swissre: {
    img: require('./logosGray/swissre.webp'),
    imgFallback: require('./logosGray/swissre.png'),
    url: 'https://www.swissre.com/',
  },
  sunflower: {
    img: require('./logosGray/sunflower.svg'),
    url: 'https://www.sunflower-labs.com/',
  },
  vescore: {
    img: require('./logosGray/vescore.webp'),
    imgFallback: require('./logosGray/vescore.png'),
    url: 'https://am.vontobel.com/en/multi-asset-boutique/vescore',
  },
  videobooks: {
    img: require('./logosGray/videobooks.webp'),
    imgFallback: require('./logosGray/videobooks.png'),
    url: 'http://www.videobooks.com/',
  },
}
const clientsOrderDesktop = [
  'bcg',
  'baloise',
  // 'css',
  'docmine',
  'swissre',
  'guuru',
  'novartis',
  'vescore',
  'videobooks',
  'sunflower',
  'fourquant',
  'nxgen',
  'kantonsrat',
  'oebb',
]

const clientsOrderMobile = [
  'bcg',
  // 'css',
  'baloise',
  'docmine',
  'guuru',
  'swissre',
  'novartis',
  'vescore',
  'sunflower',
  'fourquant',
  'videobooks',
  'nxgen',
  'oebb',
  'kantonsrat',
]

interface IClientsProps extends WithStyles<typeof ClientsStyles> {
  id?: string
}

@observer
class ClientsCls extends React.Component<IClientsProps> {
  @computed
  get isPortrait() {
    return windowStore.height > windowStore.width
  }

  render() {
    const { classes } = this.props

    const rows = Boolean(breakpoints.desktop) && !this.isPortrait ? 3 : 5
    const cols = Boolean(breakpoints.desktop) && !this.isPortrait ? 5 : 3

    const logoRows = []
    let logoCols = []
    for (let i = 0; i < rows; i++) {
      for (let j = 0; j < cols; j++) {
        const idx = cols * i + j
        const clientsOrder =
          Boolean(breakpoints.desktop) && !this.isPortrait
            ? clientsOrderDesktop
            : clientsOrderMobile
        const client = clientsOrder[idx]
        // console.log(idx, client)
        if (client != null) {
          logoCols.push(
            <div key={client} className={classes.logo}>
              <a href={clientsList[client].url}>
                <LazyImage
                  src={clientsList[client].img}
                  srcFallback={clientsList[client].imgFallback}
                  alt={client}
                />
              </a>
            </div>
          )
        }
      }
      logoRows.push(
        <div
          key={i}
          className={clsx(
            classes.logosRow,
            i === rows - 1 ? classes.lastRow : undefined
          )}
          style={{ maxHeight: 0.125 * windowStore.height }}
        >
          {logoCols}
        </div>
      )
      logoCols = []
    }

    let callToAction: JSX.Element | null = null
    callToAction = (
      <div className={classes.callToActionBox}>
        <div className={classes.callToActionText}>{callToActionText}</div>
        <a href={`mailto:${email}?subject=${subject}`}>
          <div className={classes.calltoActionButton}>{buttonText}</div>
        </a>
      </div>
    )

    return (
      <Section title="Our clients" id="clients">
        <div className={classes.text}>{text}</div>
        <div className={classes.logosContainer}>{logoRows}</div>
        {callToAction}
      </Section>
    )
  }
}

export default withStyles(ClientsStyles, { name: 'Clients', withTheme: true })(
  ClientsCls
)

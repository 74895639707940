import { MOBILE, DESKTOP } from '../../utils/styles'
import { createStyles } from '@material-ui/styles'

export const ProjectsFullpageStyles = createStyles({
  container: {
    [`@media ${MOBILE}`]: {},
    [`@media ${DESKTOP}`]: {},
  },
  section: {
    height: '100%',
    [`@media ${MOBILE}`]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  arrowDown: {
    position: 'fixed',

    [`@media ${DESKTOP}`]: {
      width: '1.5rem',
      height: '1.5rem',
      top: '3rem',
      right: '3rem',
      transform: 'rotate(90deg)',
    },
    [`@media ${MOBILE}`]: {
      top: '2.75rem',
      left: '0.5rem',
      width: '2rem',
      height: '2rem',
      padding: '0.5rem',
      '& img': {
        width: '100%',
        height: '100%',
      },
    },
  },
  projectsContainer: {
    display: 'flex',
    flex: 1,
    // height: 'calc(100% - 5rem)',
    /* -webkit-overflow-scrolling: touch;  */
    [`@media ${DESKTOP}`]: {
      // paddingLeft: '5vw',
      // paddingRight: '5vw',
      flexDirection: 'row',
      overflow: 'hidden',
      position: 'absolute',
      top: 0,
      '& > div': {
        width: '100%',
      },
    },
    [`@media ${MOBILE}`]: {
      flexDirection: 'column',
      '& > div': {
        height: '100%',
      },
    },
  },
  swipeDots: {
    bottom: '11%',
  },
  title: {
    textAlign: 'left',
    width: '100%',
    top: 0,
    left: 0,
    [`@media ${DESKTOP}`]: {
      position: 'absolute',
      fontSize: '2rem',
      padding: '10vh 10vw 4vh 10vw',
    },
    [`@media ${MOBILE}`]: {
      padding: '3rem 2.5rem 1.5rem 3rem',
      // fontSize: '1.5rem',
      // padding: '1.65rem 2.5rem 1.25rem 3rem',
      fontSize: '1.125rem',
      lineHeight: 1.4,
    },
  },
})

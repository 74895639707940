import { breakpoints } from '../stores/breakpointsStore'
import { windowStore } from '../stores/windowStore'

export const DESKTOP = '(min-width: 768px) AND (min-height: 480px)'
export const TABLET = '(min-width: 767px) AND (max-width: 1440px)' // always use after DESKTOP!
export const MOBILE = '(max-width: 767px) , (max-height: 480px)'

// Function to normalize svg path for clipping
// function normalize (str: string) {
//   let maxWidth = 0
//   let maxHeight = 0
//   let numbers = str.split(',').map(str => str.split(' ').filter(el => el).map(str => parseInt(str, 10))).map(([x, y]) => {
//     if (x > maxWidth) maxWidth = x
//     if (y > maxHeight) maxHeight = y
//     return [x, y]
//   })
// return numbers.map(([x, y]) => [x / maxWidth, y / maxHeight].join(' ')).join(',')
// }

export const giveColor = (r: number, g: number, b: number, o?: number) => {
  const red = r < 0 ? 0 : r > 255 ? 255 : r
  const green = g < 0 ? 0 : g > 255 ? 255 : g
  const blue = b < 0 ? 0 : b > 255 ? 255 : b
  const opacity = o === undefined || o > 1.0 ? 1.0 : o < 0 ? 0 : o
  return `rgba(${red}, ${green}, ${blue}, ${opacity})`
}

export const getStickyPosition = () => {
  let position = 'relative' as 'sticky' | 'relative'
  if (
    Boolean(breakpoints.desktop) ||
    (Boolean(breakpoints.phone) && !windowStore.isLandscape)
  ) {
    position = 'sticky' as 'sticky' | 'relative'
  }
  return position
}

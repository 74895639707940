import { MOBILE, DESKTOP } from '../../utils/styles'
import { createStyles } from '@material-ui/styles'

export const TeamStyles = createStyles({
  columnsBox: {
    display: 'flex',
    alignSelf: 'center',
    padding: '0 30px',
    /* max-width: 1280px; */
    /* -webkit-overflow-scrolling: touch;  */
    [`@media ${DESKTOP}`]: {
      flexDirection: 'row',
      justifyContent: 'space-evenly',
    },
    [`@media ${MOBILE}`]: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      /* height: 100vh; */
    },
  },
})

import { MOBILE, DESKTOP, TABLET } from '../../utils/styles'
import { createStyles } from '@material-ui/styles'

export const HiringStyles = createStyles({
  hiringBox: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    // minHeight: 'max-content',
    /* height: 100%; */
    // maxWidth: windowStore.width,
    /* -webkit-overflow-scrolling: touch;  */
    [`@media ${DESKTOP}`]: {
      flexDirection: 'row',
      justifyContent: 'center',
      padding: '0 10vw',
      height: 'inherit',
    },
    [`@media ${MOBILE}`]: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      padding: '0 10vw',
      height: '50%',
      minHeight: '50%',
    },
  },
  image: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > img': {
      width: '60%',
    },
    [`@media ${DESKTOP}`]: {
      flex: '33%',
    },
    [`@media ${MOBILE}`]: {
      width: '100%',
      maxHeight: 360,
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    [`@media ${DESKTOP}`]: {
      flex: '67%',
    },
  },
  contentBox: {
    flex: '50%',
    marginTop: '5vh',
    [`@media ${DESKTOP}`]: {
      padding: '0 2rem',
    },
  },
  contentTitle: {
    textTransform: 'uppercase',
    fontSize: '0.9rem',
    fontWeight: 'bold',
  },
  contentText: {
    marginTop: '0.2rem',
    fontSize: '0.8rem',
  },
  email: {
    fontSize: '0.8rem',
    fontWeight: 'bold',
  },
  mountains: {
    [`@media ${DESKTOP}`]: {
      bottom: '-25vh !important',
    },
    [`@media ${MOBILE}`]: {
      bottom: '2vh !important',
    },
  },

  mountainsBox: {
    position: 'relative',
    width: '100%',
    [`@media ${DESKTOP}`]: {
      position: 'absolute',
      bottom: '-15%',
      '@media (orientation: portrait)': {
        bottom: '-5%',
      },
    },
  },

  footer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    [`@media ${MOBILE}`]: {
      paddingBottom: '3rem',
    },
  },
  checkJobsButton: {
    fontSize: '0.875rem',
    fontWeight: 'bold',
    padding: '0.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#3BB5A2',
    [`@media ${DESKTOP}`]: {
      width: '10rem',
      height: '2.25rem',
      borderRadius: 5,
      '&:hover': {
        boxShadow: 'inset 0 0 20px 20px rgba(0, 0, 0, 0.1)',
      },
    },
    [`@media ${TABLET}`]: {
      width: '8rem',
      fontSize: '0.7rem',
      height: '1.8rem',
    },
    [`@media ${MOBILE}`]: {
      width: '8rem',
      height: '2.25rem',
      // alignSelf: 'center',
      borderRadius: 5,
    },
  },
})
